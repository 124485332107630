import { useModalSelectService } from "@Hooks/modalHooks/useModalSelectService";
import style from "./SelectServices.module.css";
import { ServiceIcon } from "@Models/icons";
// @ts-ignore
import { Icon, ModalAcceptCancel, TextInput } from "@viuti/recursos";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { formatToSoles } from "@Utilities/FormatsHandlers";
import {
  ItemService,
  updatePriceServiceId,
  updateSavedServices,
} from "@ReduxService/states/promotionsFormSlice";

export const SelectServices = () => {
  const { handleOpenModalSelectServiceClick, modalProps, savedServices } =
    useModalSelectService();
  const dispatch = useAppDispatch();
  const services = useAppSelector((state) => state.services.services);
  const form = useAppSelector((state) => state.promotionForm.form);
  const message =
    form.typePromotion === "Paquete"
      ? " en este paquete."
      : " en esta promoción.";

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { name, value } = e.target;
    if (value === "0") {
      return;
    }
    // buscamos el índice del servicio en el array de savedServices
    const serviceIndex = savedServices.findIndex(
      (service: ItemService) => service.idService === id
    );

    // creamos una copia del servicio que queremos actualizar
    const updatedService = { ...savedServices[serviceIndex], quantity: value };

    // creamos un nuevo array de savedServices con el servicio actualizado
    const updatedServices = [
      ...savedServices.slice(0, serviceIndex),
      updatedService,
      ...savedServices.slice(serviceIndex + 1),
    ];

    dispatch(updateSavedServices(updatedServices));
    dispatch(updatePriceServiceId(updatedServices));
  };

  const total = savedServices?.reduce(
    // @ts-ignore
    (total: number, savedService: ItemService) => {
      const service = services.find(
        (s: ItemService) => s.idService === savedService.idService
      );
      return service
        ? total + Number(service.price) * Number(savedService.quantity)
        : total;
    },
    0
  );

  return (
    <div id="productos-y-servicios" className={style.containerSelectServices}>
      <label className={`${style.label} ${style.title}`} htmlFor="servicios">
        <div className={style.icon}>
          <Icon path={ServiceIcon} size={14} color="#45348e" alt="Editar" />
        </div>
        Servicios
      </label>
      <p className={style.description}>
        Selecciona los servicios que deseas incluir
        {message}
        <button
          onClick={handleOpenModalSelectServiceClick}
          className={style.button_open_services}
        >
          dando clic aquí
        </button>
        .
      </p>
      {!!savedServices.length && form.typePromotion !== "Paquete" && (
        <p className={style.selected}>
          <b>
            Tienes {savedServices.length} servicios seleccionados
            {message}
          </b>
        </p>
      )}
      {!!savedServices.length && form.typePromotion === "Paquete" && (
        <div className={style.selected}>
          <ul className={style.productList}>
            {savedServices.map((savedServiceId) => {
              const service = services.find(
                (s: ItemService) => s.idService === savedServiceId.idService
              );
              return service ? (
                <li key={service.idService} className={style.productItem}>
                  <span>{service.serviceName}</span>
                  <TextInput
                    label={""}
                    type="number"
                    name="quantity"
                    placeholder={"0"}
                    value={savedServiceId.quantity}
                    handleChange={(e) =>
                      handleInputChange(e, savedServiceId.idService)
                    }
                    className={style.input}
                    id="datos-basicos-servicios"
                    required
                    width="50px"
                  />
                  <span className={style.price}>
                    {formatToSoles(service.price)}
                  </span>
                </li>
              ) : null;
            })}
          </ul>
          <div className={style.totalContainer}>
            <p>Total</p>
            {/* @ts-ignore */}
            <p>{formatToSoles(total)}</p>
          </div>
        </div>
      )}
      <ModalAcceptCancel {...modalProps} />
    </div>
  );
};
