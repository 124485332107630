import { getPromotionDetail_adapter } from "@Adapters/promotions/getPromotionDetail.adapter";
import { useAppDispatch } from "@Hooks/store";
import { updateCriticalErrorFormPromotion } from "@ReduxService/states/criticalErrorSlice";
import { updateLoaderFormPromotion } from "@ReduxService/states/loaderSlice";
import {
  clearForm,
  setPromotionForm,
} from "@ReduxService/states/promotionsFormSlice";
import {
  clearInitialForm,
  setInitialForm,
} from "@ReduxService/states/promotionsInitialFormSlice";
import React, { useEffect } from "react";

export const useAutoCompletePromotionForm = (
  idPromotion: string,
  paymentMethods: any
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    idPromotion && dispatch(updateLoaderFormPromotion(true));
    (async () => {
      if (!idPromotion || !paymentMethods.length) {
        dispatch(clearForm());
        dispatch(clearInitialForm());
        return;
      }
      const response = await getPromotionDetail_adapter(
        idPromotion,
        paymentMethods
      );
      if (!response.isSuccess) {
        dispatch(updateCriticalErrorFormPromotion(true));
        dispatch(updateLoaderFormPromotion(false));
        return;
      } else {
        dispatch(updateLoaderFormPromotion(false));
        dispatch(setPromotionForm(response.data));
        dispatch(setInitialForm(response.data));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPromotion, paymentMethods]);
};
