import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface ILoaderProps {
  loaderPromotions: boolean;
  loaderFormPromotion: boolean;
  loaderServices: boolean;
  loaderCategories: boolean;
}

const initialState: ILoaderProps = {
  loaderPromotions: false,
  loaderFormPromotion: true,
  loaderServices: false,
  loaderCategories: false,
};

export const loaderSlice = createSlice({
  name: "loaderConfig",
  initialState: initialState,
  reducers: {
    updateLoaderPromotions(state, action: PayloadAction<boolean>) {
      state.loaderPromotions = action.payload;
    },
    updateLoaderFormPromotion(state, action: PayloadAction<boolean>) {
      state.loaderFormPromotion = action.payload;
    },
    updateLoaderServices(state, action: PayloadAction<boolean>) {
      state.loaderServices = action.payload;
    },
    updateLoaderCategories(state, action: PayloadAction<boolean>) {
      state.loaderCategories = action.payload;
    },
  },
});

export default loaderSlice.reducer;
export const {
  updateLoaderPromotions,
  updateLoaderFormPromotion,
  updateLoaderServices,
  updateLoaderCategories,
} = loaderSlice.actions;
