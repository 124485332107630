import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getProducts_service } from "@Services/products/getProducts.service";

export const getProducts_adapter = async (dispatch) => {
  const response = await getProducts_service();

  if (!response.isSuccess) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );

    return { ...response, data: [] };
  }

  const adaptProducts = (product) => {
    return {
      idProduct: product.productId,
      productName: product.productName,
      price: product.price,
      idItem: product.productId,
      quantity: product.stock,
    };
  };

  const data = response.data.map(adaptProducts);

  return {
    ...response,
    data: data,
  };
};
