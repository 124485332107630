export const PROMOTION_ROUTE = {
  HOME_ROUTE: "/",
  CREATE_PROMOTION: "/nuevo-paquete",
  EDIT_PROMOTION: "/ver-paquete/:id",
  PROMO_UNIT: "/unitario",
};

export const GIFTCARD_ROUTE = {
  CREATE_GIFTCARD: "/nueva-giftcard",
  EDIT_GIFTCARD: "/editar-giftcard/:id",
  CREATE_MASSIVE_GIFTCARDS: "/carga-masiva-giftcards",
};

export const CUSTOMER_CATEGORY_ROUTE = {
  CREATE_CUSTOMER_CATEGORY: "/nueva-categoria",
  EDIT_CUSTOMER_CATEGORY: "/editar-categoria/:idCategory",
};
