// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jaMAtKoHdAFJBQ8lGVC7 {
  display: flex;
  gap: 12px;
  margin-top: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.S_Erlqv3wjXRZ4S0XCuT {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.xlUUW3x5h0DJxQkb3wUg {
  cursor: pointer;
}

.MtNOjHIz0ALR9uGRgKWm {
  border-radius: 4px;
  padding: 10px;
  gap: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #45348e;
  background-color: #937cf42e;
}

.I58SnxJ3uLtNLoIMd0IO {
  display: flex;
  color: #4d4d4d;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-label);
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Promotions/PromotionForm/components/SelectPaymentMethods/SelectPaymentMethods.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,cAAc;EACd,iCAAiC;EACjC,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".selectedMethods {\n  display: flex;\n  gap: 12px;\n  margin-top: 8px;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.select_all_container {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 8px;\n}\n\n.select_all {\n  cursor: pointer;\n}\n\n.selectedMethod {\n  border-radius: 4px;\n  padding: 10px;\n  gap: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px;\n  font-weight: 400;\n  color: #45348e;\n  background-color: #937cf42e;\n}\n\n.label {\n  display: flex;\n  color: #4d4d4d;\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-label);\n  font-style: normal;\n  font-weight: 500;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedMethods": `jaMAtKoHdAFJBQ8lGVC7`,
	"select_all_container": `S_Erlqv3wjXRZ4S0XCuT`,
	"select_all": `xlUUW3x5h0DJxQkb3wUg`,
	"selectedMethod": `MtNOjHIz0ALR9uGRgKWm`,
	"label": `I58SnxJ3uLtNLoIMd0IO`
};
export default ___CSS_LOADER_EXPORT___;
