import { updateCodePromotion } from "@ReduxService/states/promotionsFormSlice";
import style from "../../PromotionForm.module.css";
// @ts-ignore
import { TextInput } from "@viuti/recursos";
import { usePromotionContext } from "@Context/promotionContext";

export const PromotionCode = () => {
  const { form, dispatch } = usePromotionContext();
  const handleInputChange = (e) => {
    const { value } = e.target;
    dispatch(updateCodePromotion(value));
  };

  return (
    <div>
      <TextInput
        label="Código"
        type="text"
        name="codePromotion"
        placeholder="Ingresar el código del cupón"
        value={form.codePromotion}
        handleChange={handleInputChange}
        className={style.input}
        required
      />
    </div>
  );
};
