import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { useEffect, useState } from "react";
import style from "./ModalSelectProducts.module.css";
// @ts-ignore
import { SearchWithColoredBorder } from "@viuti/recursos";
import {
  ItemProduct,
  updateSelectedProducts,
} from "@ReduxService/states/promotionsFormSlice";

export const ModalSelectProducts = () => {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.products.products);
  const { selectedProducts } = useAppSelector((state) => state.promotionForm);
  const savedProducts: ItemProduct[] = selectedProducts;
  const [search, setSearch] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    setFilteredProducts(
      products.filter((product: ItemProduct) =>
        product.productName.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, products]);

  const handleSelectAll = () => {
    if (savedProducts?.length === filteredProducts.length) {
      dispatch(updateSelectedProducts([]));
    } else {
      dispatch(updateSelectedProducts(filteredProducts));
    }
  };

  const handleCheckboxChange = (product: ItemProduct) => {
    if (savedProducts.some((p) => p.idProduct === product.idProduct)) {
      dispatch(
        updateSelectedProducts(
          savedProducts.filter((p) => p.idProduct !== product.idProduct)
        )
      );
    } else {
      dispatch(updateSelectedProducts([...savedProducts, product]));
    }
  };

  const searchProps = {
    value: search,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearch(e.target.value),
    placeholder: "Buscar productos...",
    setValue: setSearch,
    withoutTheContainerWithTheResults: true,
    clearInput: false,
  };

  const isProductSelected = (id) => {
    const isSelected = savedProducts.some((p) => p.idProduct === id);
    return isSelected;
  };

  return (
    <div className={style.modal_select_services}>
      <p>Selecciona los productos que deseas agregar a la promoción</p>
      <SearchWithColoredBorder {...searchProps} />
      <button onClick={handleSelectAll} className={style.button_select_all}>
        {selectedProducts?.length === filteredProducts.length
          ? "Deseleccionar todos"
          : "Seleccionar todos"}
      </button>
      <div className={style.products}>
        {filteredProducts.map((product) => (
          <label key={product.idProduct} className={style.product}>
            <input
              type="checkbox"
              id={String(product.idProduct)}
              name={product.productName}
              value={product.idProduct}
              checked={isProductSelected(product.idProduct)}
              onChange={() => handleCheckboxChange(product)}
            />
            <span>{product.productName}</span>
          </label>
        ))}
      </div>
    </div>
  );
};
