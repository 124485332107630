export const convertToMinutes = (time) => {
  const [hour, minute] = time.split(/[: ]/);
  // Eliminamos la parte del periodo AM/PM
  let hours = parseInt(hour);
  const minutes = parseInt(minute);

  // Ajustamos el cálculo para el formato de 24 horas
  if (hours < 0 || hours > 23) {
    throw new Error("La hora debe estar entre 0 y 23");
  }

  return hours * 60 + minutes; // Retorna el tiempo en minutos para fácil comparación
};

// Modificamos la función para generar horarios en formato 24h
export const generateTimeSlots = () => {
  const times = [];

  for (let hour = 0; hour < 24; hour++) {
    // Cambiamos el rango de horas a 0-23
    for (let minutes = 0; minutes < 60; minutes += 15) {
      let minuteString = minutes === 0 ? "00" : minutes;
      times.push(`${hour}:${minuteString}`); // Eliminamos el periodo AM/PM
    }
  }

  return times;
};
