import style from "./RowActionsPromotions.module.css";
import { CommonArrowRightIcon, TrashCanIcon } from "@Models/icons";

// @ts-ignore
import { Icon, ModalAcceptCancel } from "@viuti/recursos";
import useDeletePromotion from "@Hooks/modalHooks/useDeletePromotion";
import useDeleteGiftcards from "@Hooks/modalHooks/useDeleteGiftcard";

export const RowActionsPromotions = ({
  item,
  isHovered,
  screenWidth,
  view = "PROMOTION",
  handleReset,
}) => {
  const { handleOpenModalDelete, modalProps } = useDeletePromotion(
    item.id,
    handleReset
  );

  return (
    <span className={style.row_actions}>
      {screenWidth > 900 && (
        <div
          style={{ opacity: isHovered === item.id ? 1 : 0 }}
          className={style.icon__edit}
        >
          <Icon
            path={CommonArrowRightIcon}
            color="#45348E"
            size={16}
            alt="Editar"
          />
        </div>
      )}
      <button onClick={handleOpenModalDelete} className={style.icon__delete}>
        <Icon path={TrashCanIcon} color="#C36363" size={16} alt="Eliminar" />
      </button>
      <ModalAcceptCancel {...modalProps} />
    </span>
  );
};
