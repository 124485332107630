import { updateConditions } from "@ReduxService/states/promotionsFormSlice";
import style from "../../PromotionForm.module.css";
// @ts-ignore
import { TextAreaInput } from "@viuti/recursos";
import { Waypoint } from "react-waypoint";
import { usePromotionContext } from "@Context/promotionContext";
import { useState } from "react";

export const PromotionConditions = ({ setCurrentView }) => {
  const { form, dispatch } = usePromotionContext();
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { value } = e.target;
    dispatch(updateConditions(value));
  };

  const handleBlur = () => {
    if (!form.conditions) {
      setError("Debes ingresar los términos y condiciones");
    } else {
      setError("");
    }
  };

  return (
    <div>
      <TextAreaInput
        label="Términos y Condiciones"
        name="conditions"
        placeholder="Términos y Condiciones"
        value={form.conditions}
        handleChangeTemp={handleInputChange}
        handleBlur={handleBlur}
        className={style.textarea}
        required
        error={error}
        touched={error}
      />
      <Waypoint
        onEnter={() => {
          setCurrentView(1);
        }}
        onLeave={() => {
          setCurrentView(2);
        }}
      />
    </div>
  );
};
