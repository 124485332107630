import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
export interface CustomerCategory {
  id: number;
  name: string;
  discountService: number;
  discountProduct: number;
}

export interface CustomerCategoryState {
  customerCategory: CustomerCategory[];
}

const initialStateCustomerCategory: CustomerCategoryState = {
  customerCategory: [],
};

const customerCategorySlice = createSlice({
  name: "customerCategory",
  initialState: initialStateCustomerCategory,
  reducers: {
    updateCustomerCategory(state, action: PayloadAction<CustomerCategory[]>) {
      state.customerCategory = action.payload;
    },
  },
});

export default customerCategorySlice.reducer;
export const { updateCustomerCategory } = customerCategorySlice.actions;
