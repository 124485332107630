import React from "react";
import style from "./TotalPackageValue.module.css";
import { formatToSoles } from "@Utilities/FormatsHandlers";

export const TotalPackageValue = ({ total }: { total: number }) => {
  return (
    <div className={style.totalPackagePrice}>
      <b>Valor estimado</b>
      <b>{formatToSoles(total)}</b>
    </div>
  );
};
