// @ts-ignore
import { SlideNotification, ModalAcceptCancel } from "@viuti/recursos";
import { clearNotification } from "@ReduxService/states/notificationSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/store";

export const GlobalComponents = () => {
  const dispatch = useAppDispatch();
  const notificationState = useAppSelector((state) => state.notificationConfig);
  const modalState = useAppSelector((state) => state.modalConfig);
  return (
    <>
      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
      {/* <ModalAcceptCancel {...modalState} /> */}
    </>
  );
};
