import style from "./CreateMassiveGiftcards.module.css";
import {
  FileUploader,
  InfoNote,
  HeaderNavigation,
  //   SlideNotification,
  // @ts-ignore
} from "@viuti/recursos";
import { HiArrowDownTray } from "react-icons/hi2";

const CreateMassiveGiftcards = () => {
  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title="Carga masiva de giftcards"
        previousAction={() =>
          window.location.replace(`/promociones/nueva-giftcard`)
        }
        previousActionMovement={"back"}
        buttonProps={{
          textBttn: "Confirmar",
          handleClick: () => {},
          isHidden: false,
          isPrimary: true,
          isDisabled: false,
          isLoading: false,
        }}
      />
      <div className={style.create__masive__products__container}>
        <section className={style.create__masive__products__section}>
          <h1 className={style.create__masive__products__title}>
            Instrucciones
          </h1>
          <p className={style.create__masive__products__description}>
            Descarga, completa y sube una planilla con los datos de los nuevos
            giftcards que quieres crear en tu almacén en solo cuatro pasos.
          </p>
        </section>
        <InfoNote type="warning">
          <p>
            Antes de subir la planilla, asegúrate de que cumple con las
            siguientes condiciones:
          </p>
          <ul>
            <li>
              <p>
                No cambies los nombres de las columnas ni elimines las filas o
                columnas preexistentes.
              </p>
            </li>
            <li>
              <p>
                Recuerda que los giftcards que crees no podrán ser modificados
                masivamente. Para modificar giftcards de forma individual puedes
                hacerlo desde la sección de giftcards.
              </p>
            </li>
            <li>
              <p>
                Completa solo las filas en blanco, no elimines las primeras
                filas de colores que tienen los nombres de las columnas y las
                ayudas.
              </p>
            </li>
            <li>
              <p>
                Si tu giftcard tiene variantes, puedes crearlas copiando las
                filas.
              </p>
            </li>
          </ul>
        </InfoNote>
        <div className={style.create__masive__products__actions__container}>
          <button
            className={style.download__template__button}
            onClick={() => {}}
            disabled={false}
          >
            <span className={style.download__template__button__icon}>
              <HiArrowDownTray size={18} />
            </span>
            <span className={style.download__template__button__text}>
              <h3 className={style.download__template__button__text__title}>
                Descarga la planilla para crear giftcards masivamente.
              </h3>
              <p
                className={style.download__template__button__text__description}
              >
                Configura la planilla con los datos de los giftcards que quieres
                crear en tu almacén y súbela a Prikly.
              </p>
            </span>
          </button>
          <FileUploader
            label="Subir planilla"
            file={""}
            setFile={() => {}}
            accept={[".xls", ".xlsx"]}
          />
        </div>
      </div>
      {/* <SlideNotification state={""} clearStatus={() => {}} /> */}
    </div>
  );
};

export default CreateMassiveGiftcards;
