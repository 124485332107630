import { updateNotification } from "@ReduxService/states/notificationSlice";
import { clearForm } from "@ReduxService/states/promotionsFormSlice";
import { clearInitialForm } from "@ReduxService/states/promotionsInitialFormSlice";
import { putEditPackage_service } from "@Services/promotions/package/putEditPackage.service";

export const putEditPackage_adapter = async (
  id,
  form,
  initialForm,
  savedServices,
  savedProducts,
  initialSavedServices,
  initialSavedProducts,
  dispatch,
  setIsLoadingCreatePromotion,
  navigation
) => {
  setIsLoadingCreatePromotion(true);
  const editData: any = {
    promotionId: Number(id),
  };

  if (form.promotionName !== initialForm.promotionName) {
    editData.promotionName = form.promotionName;
  }
  if (form.startDate !== initialForm.startDate) {
    editData.startDate = form.startDate;
  }
  if (form.endDate !== initialForm.endDate) {
    editData.endDate = form.endDate;
  }

  if (form.withoutLimit) {
    if (form.withoutLimit !== initialForm.withoutLimit) {
      editData.stock = null;
    }
  } else if (String(form.stock) !== String(initialForm.stock)) {
    editData.stock = Number(form.stock);
  }

  if (form.conditions !== initialForm.conditions) {
    editData.conditions = form.conditions;
  }

  // Solo agregar nuevos productos si hay cambios y no existen en initialSavedProducts
  if (
    savedProducts?.length > 0 &&
    !savedProducts.every(
      (product, index) =>
        String(product.idProduct) ===
          String(initialSavedProducts[index]?.idProduct) &&
        String(product.quantity) ===
          String(initialSavedProducts[index]?.quantity)
    )
  ) {
    // Filtrar solo los productos que son realmente nuevos
    const newProducts = savedProducts.filter(
      (product) =>
        !initialSavedProducts.some(
          (initialProduct) =>
            String(initialProduct.idProduct) === String(product.idProduct)
        )
    );

    if (newProducts.length > 0) {
      editData.newProducts = newProducts.map((product) => ({
        idProduct: Number(product.idProduct),
        quantity: Number(product.quantity),
      }));
    }
  }

  // Solo agregar productos editados si hay cambios
  const editedProducts = initialSavedProducts.filter((initialProduct) =>
    savedProducts.some(
      (product) =>
        String(product.idProduct) === String(initialProduct.idProduct) &&
        String(product.quantity) !== String(initialProduct.quantity)
    )
  );

  if (editedProducts.length > 0) {
    editData.editProducts = editedProducts.map((product) => ({
      idProduct: Number(product.idProduct),
      quantity:
        Number(
          savedProducts.find((p) => p.idProduct === product.idProduct)?.quantity
        ) || Number(product.quantity),
      idPromotionDetail: Number(product.idItem),
    }));
  }

  // Manejar eliminación de productos
  const deletedProducts = initialSavedProducts.filter(
    (initialProduct) =>
      !savedProducts.some(
        (product) => product.idProduct === initialProduct.idProduct
      )
  );

  if (deletedProducts.length > 0) {
    editData.deleteProducts = deletedProducts.map((product) =>
      Number(product.idProduct)
    );
  }

  // Solo agregar nuevos servicios si hay cambios
  if (
    savedServices.length > 0 &&
    !savedServices.every(
      (service, index) =>
        String(service.idService) ===
          String(initialSavedServices[index]?.idService) &&
        String(service.quantity) ===
          String(initialSavedServices[index]?.quantity)
    )
  ) {
    editData.newService = savedServices
      .filter(
        (service) =>
          !initialSavedServices.some(
            (initialService) =>
              String(initialService.idService) === String(service.idService)
          )
      )
      .map((service) => ({
        priceServiceId: Number(service.idService),
        quantity: Number(service.quantity),
      }));
  }

  // Solo agregar servicios editados si hay cambios
  const editedServices = initialSavedServices.filter((initialService) =>
    savedServices.some(
      (service) =>
        String(service.idService) === String(initialService.idService) &&
        String(service.quantity) !== String(initialService.quantity)
    )
  );

  if (editedServices.length > 0) {
    editData.editServices = editedServices.map((service) => ({
      priceServiceId: Number(service.idService),
      quantity:
        Number(
          savedServices.find((s) => s.idService === service.idService)?.quantity
        ) || Number(service.quantity),
      idPromotionDetail: Number(service.idItem),
    }));
  }

  // Manejar eliminación de servicios
  const deletedServices = initialSavedServices.filter(
    (initialService) =>
      !savedServices.some(
        (service) => service.idService === initialService.idService
      )
  );

  if (deletedServices.length > 0) {
    editData.deleteServices = deletedServices.map((service) =>
      Number(service.idService)
    );
  }

  if (form.packageCost !== initialForm.packageCost) {
    editData.editPromotionType = {
      price: form.packageCost,
    };
  }

  // 1. Manejar días eliminados y nuevos días solo si hay cambios
  const areDaysEqual =
    initialForm.daysAndHoursOfAvailability?.length ===
      form.daysAndHoursOfAvailability?.length &&
    initialForm.daysAndHoursOfAvailability?.every((initialDay, index) => {
      const currentDay = form.daysAndHoursOfAvailability[index];
      return (
        initialDay.codeDay === currentDay.codeDay &&
        initialDay.startHour === currentDay.startHour &&
        initialDay.endHour === currentDay.endHour
      );
    });

  if (!areDaysEqual) {
    // 1. Manejar días eliminados
    const deletedDays =
      initialForm.daysAndHoursOfAvailability?.map((day) => day.itemId) || [];
    if (deletedDays.length > 0) {
      editData.deleteDayAvailable = deletedDays;
    }

    // 2. Manejar nuevos días
    const newDays = form.daysAndHoursOfAvailability?.map((day) => ({
      codeDay: day.codeDay,
      startHour: day.startHour,
      endHour: day.endHour,
    }));

    if (newDays.length > 0) {
      editData.newDayAvailable = newDays;
    }
  }

  // Manejar nuevos medios de pago
  let newPaymentMethods: number[] = [];

  newPaymentMethods = form.paymentMethods
    .filter(
      (method) =>
        !initialForm.paymentMethods.some(
          (initialMethod) =>
            initialMethod.idPaymentMethod === method.idPaymentMethod
        )
    )
    .map((method) => method.idPaymentMethod);

  // Si hay nuevos métodos de pago, se agregan a newMeansPayment
  if (newPaymentMethods.length > 0) {
    // Comparar los IDs de los métodos de pago y su cantidad
    const arePaymentMethodsEqual =
      form.paymentMethods.length === initialForm.paymentMethods.length &&
      form.paymentMethods.every(
        (method, index) =>
          method.idPaymentMethod ===
          initialForm.paymentMethods[index].idPaymentMethod
      );

    // Si son iguales, salir de la función
    if (!arePaymentMethodsEqual) {
      editData.newMeansPayment = newPaymentMethods;
    }
  }

  const deletedPaymentMethods = initialForm.paymentMethods.filter(
    (initialMethod) =>
      !form.paymentMethods.some(
        (method) => method.idPaymentMethod === initialMethod.idPaymentMethod
      )
  );

  if (deletedPaymentMethods.length > 0) {
    editData.deleteMeansPayment = deletedPaymentMethods.map(
      (method) => method.idPaymentMethod
    );
  }

  const response = await putEditPackage_service(editData);
  if (response.isSuccess === false) {
    setIsLoadingCreatePromotion(false);
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
  } else {
    setIsLoadingCreatePromotion(false);
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
    dispatch(clearForm());
    dispatch(clearInitialForm());
    navigation("/");
  }
};

// {
//   "promotionId": 0,
//   "promotionName": "string",
//   "startDate": "string",
//   "endDate": "string",
//   "stock": 0,
//   "conditions": "string",
//   "newProducts": [
//     {
//       "idProduct": 0,
//       "quantity": 0
//     }
//   ],
//   "editProducts": [
//     {
//       "idPromotionDetail": 0,
//       "idProduct": 0,
//       "quantity": 0
//     }
//   ],
//   "newService": [
//     {
//       "priceServiceId": 0,
//       "quantity": 0
//     }
//   ],
//   "editServices": [
//     {
//       "idPromotionDetail": 0,
//       "priceServiceId": 0,
//       "quantity": 0
//     }
//   ],
//   "deletePromotionDetails": [
//     0
//   ],
//   "newDayAvailable": [
//     {
//       "codeDay": 0,
//       "startHour": "string",
//       "endHour": "string"
//     }
//   ],
//   "editDayAvailable": [
//     {
//       "dayAvailablePromotionId": 0,
//       "codeDay": 0,
//       "startHour": "string",
//       "endHour": "string"
//     }
//   ],
//   "deletaDayAvailable": [
//     0
//   ],
//   "editPromotionType": {
//     "price": 0
//   },
//   "newMeansPayment": [
//     0
//   ],
//   "deleteMeansPayment": [
//     0
//   ]
// }
