import { getCustomerCategoryDetail_adapter } from "@Adapters/customerCategory/getCustomerCategoryDetail.adapter";
import { useAppDispatch } from "@Hooks/store";
import { updateLoaderCategories } from "@ReduxService/states/loaderSlice";
import React, { useEffect } from "react";

export const useCustomerCategoryDetail = (id, form, setForm) => {
  const dispatch = useAppDispatch();
  const [formIsChanged, setFormIsChanged] = React.useState(true);
  const [initialForm, setInitialForm] = React.useState({
    customerCategoryName: "",
    serviceDiscount: 0,
    productsDiscount: 0,
  });
  useEffect(() => {
    (async () => {
      dispatch(updateLoaderCategories(true));
      const response = await getCustomerCategoryDetail_adapter(dispatch, id);
      if (response.isSuccess) {
        const category = response.data.customerCategoryDetail;
        setForm({
          customerCategoryName: category.name,
          serviceDiscount: category.discountService,
          productsDiscount: category.discountProduct,
        });
        setInitialForm({
          customerCategoryName: category.name,
          serviceDiscount: category.discountService,
          productsDiscount: category.discountProduct,
        });
      }
      dispatch(updateLoaderCategories(false));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      form.customerCategoryName !== initialForm.customerCategoryName ||
      String(form.serviceDiscount) !== String(initialForm.serviceDiscount) ||
      String(form.productsDiscount) !== String(initialForm.productsDiscount)
    ) {
      setFormIsChanged(false);
    } else {
      setFormIsChanged(true);
    }
  }, [form, initialForm]);

  return { initialForm, formIsChanged };
};
