import { LoadingPulseIcon } from "@Models/icons";
import style from "./Loader.module.css";

export const Loader = () => {
  return (
    <figure className={style.loadingIcon}>
      <img src={LoadingPulseIcon} alt="Cargando..." />
    </figure>
  );
};
