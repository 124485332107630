import { PromotionForm } from "@ReduxService/states/promotionsFormSlice";
import { getPromotionDetail_service } from "@Services/promotions/getPromotionDetail.service";
import { capitalizeFirstLetter } from "@Utilities/CapitalizeWords";
import { formatToSoles } from "@Utilities/FormatsHandlers";

export const getPromotionDetail_adapter = async (
  id: any,
  paymentMethods: any
) => {
  const response = await getPromotionDetail_service(id);
  if (!response.isSuccess) {
    return {
      ...response,
      data: null,
    };
  }
  // const typePromotion =
  //   response.data.promotionType.idPromotionType === 3 ? "Paquete" : "Descuento";

  const formatDate = (date: string) => {
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };

  const formatCode =
    response.data.codePromotion === "Código no disponible"
      ? ""
      : response.data.codePromotion;

  const formatPaymentMethods = paymentMethods.map((item: any) => ({
    idPaymentMethod: item?.id,
    name: item.name,
  }));

  const adaptSavedProducts =
    response.data?.promotionProductItems?.map((item: any) => ({
      idProduct: item?.productId,
      productName: item?.productName,
      price: item?.price,
      idItem: item?.promotionDetailId,
      quantity: item?.quantity,
    })) || [];

  const adaptSavedServices =
    response?.data?.serviceItems?.map((item: any) => ({
      idService: item?.priceServiceId,
      serviceName: item?.serviceName,
      price: item?.price,
      idItem: item?.promotionDetailId,
      quantity: item?.quantity,
    })) || [];

  const adaptPaymentMethods =
    response.data.meansPayment?.map((item: any) => ({
      itemId: item.promotionMeansPaymentId,
      idPaymentMethod: item?.subCategoryPaymentId,
      name: `${capitalizeFirstLetter(
        item?.categoryPaymentDescription
      )} (${capitalizeFirstLetter(item?.subCategoryPaymentDescription)})`,
    })) || [];

  const updateDaysAndHoursOfAvailability =
    response.data.dayAvailable?.map((item: any) => ({
      codeDay: item?.day,
      startHour: item?.startHour?.startsWith("0")
        ? item?.startHour?.slice(1)
        : item?.startHour,
      endHour: item?.endHour?.startsWith("0")
        ? item?.endHour?.slice(1)
        : item?.endHour,
      itemId: item?.promotionDayAvailableId,
    })) || [];

  const adaptPromotionDetail: PromotionForm = {
    form: {
      typePromotion: "Paquete",
      promotionName: response.data.packageName,
      startDate: formatDate(response.data.startDate),
      endDate: formatDate(response.data.endDate),
      // completedAvailability: response.data.dayAvailable.length === 0,
      completedAvailability: response.data.dayAvailable === null,
      daysAndHoursOfAvailability: updateDaysAndHoursOfAvailability,
      codePromotion: formatCode,
      stock: response.data.stock,
      withoutLimit: response.data.stock === null,
      paymentMethods:
        response.data.meansPayment === null
          ? // response.data.meansPayment.length === 0
            formatPaymentMethods
          : adaptPaymentMethods,
      priceServiceId: response.data.serviceItems?.map(
        (item: any) => item?.priceServiceId
      ),
      packageCost: formatToSoles(response.data.price, false),
      localProductId: response.data.promotionProductItems?.map(
        (item: any) => item.productId
      ),
      conditions: response.data.condition,
      localId: response.data.localId,
    },
    savedServices: adaptSavedServices,
    savedProducts: adaptSavedProducts,
    selectedServices: adaptSavedServices,
    selectedProducts: adaptSavedProducts,
  };
  return {
    ...response,
    data: adaptPromotionDetail,
  };
};

// {
//   "status": 200,
//   "message": "La operación se realizó con éxito",
//   "data": {
//       "typePromotion": 3,
//       "packageId": 20,
//       "stock": null,
//       "startDate": "08/11/2024",
//       "endDate": "08/11/2024",
//       "packageName": "asdasd 2",
//       "price": 0.0,
//       "condition": "asd",
//       "meansPayment": [
//           {
//               "promotionMeansPaymentId": 0,
//               "categoryPaymentDescription": null,
//               "categoryPaymentId": 0,
//               "subCategoryPaymentDescription": null,
//               "subCategoryPaymentId": 0
//           }
//       ],
//       "dayAvailable": [
//           {
//               "promotionDayAvailableId": 0,
//               "day": 0,
//               "startHour": null,
//               "endHour": null
//           }
//       ],
//       "serviceItems": [
//           {
//               "promotionDetailId": 65,
//               "priceServiceId": 1213,
//               "serviceName": "Corte de pelo para niños - Corte de pelo niño",
//               "quantity": 1,
//               "price": 5.90
//           }
//       ],
//       "promotionProductItems": [
//           {
//               "promotionDetailId": 66,
//               "productId": 111896,
//               "localProductId": 0,
//               "productName": "Mouse Logitech G Pro Wireless",
//               "quantity": 1,
//               "price": 399.000
//           }
//       ]
//   }
// }
