import { IPaymentMethod } from "@Models/interfaces/paymentMethods";
import { AppDispatch } from "@ReduxService/index";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getPaymentMethods_service } from "@Services/paymentMethods/paymentMethods.service";
import { capitalizeFirstLetter } from "@Utilities/CapitalizeWords";

export const getPaymentMethods = async (dispatch: AppDispatch) => {
  const response = await getPaymentMethods_service();
  if (!response.isSuccess) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
    return {
      ...response,
      data: [],
    };
  }

  const subPayments = response.data.map((paymentMethod: any) => {
    // Se agrega la categoría al nombre de la subcategoría
    return paymentMethod.subCategoryPayments.map((subPaymentCategory: any) => {
      const categoryName = capitalizeFirstLetter(
        paymentMethod.categoryPaymnetName
      );
      const subCategoryName = capitalizeFirstLetter(
        subPaymentCategory.subCategoryPaymentName
      );

      return {
        id: subPaymentCategory.subCategoryPaymentId,
        name:
          categoryName === subCategoryName
            ? subCategoryName
            : `${categoryName} (${subCategoryName})`, // Modificación aquí
      };
    });
  });

  const formattedResponse = subPayments.flat();

  return {
    isSuccess: response.isSuccess,
    status: response.status,
    data: formattedResponse,
    message: response.message,
  };
};

// [
//   {
//       "categoryPaymnetName": "efectivo",
//       "categoryPaymnetId": 1,
//       "subCategoryPayments": [
//           {
//               "subCategoryPaymentId": 1,
//               "subCategoryPaymentName": "efectivo"
//           }
//       ]
//   },
//   {
//       "categoryPaymnetName": "débito",
//       "categoryPaymnetId": 2,
//       "subCategoryPayments": [
//           {
//               "subCategoryPaymentId": 2,
//               "subCategoryPaymentName": "visa"
//           },
//           {
//               "subCategoryPaymentId": 3,
//               "subCategoryPaymentName": "mastercard"
//           }
//       ]
//   },
//   {
//       "categoryPaymnetName": "crédito",
//       "categoryPaymnetId": 3,
//       "subCategoryPayments": [
//           {
//               "subCategoryPaymentId": 4,
//               "subCategoryPaymentName": "visa"
//           },
//           {
//               "subCategoryPaymentId": 5,
//               "subCategoryPaymentName": "mastercard"
//           },
//           {
//               "subCategoryPaymentId": 6,
//               "subCategoryPaymentName": "diners"
//           },
//           {
//               "subCategoryPaymentId": 7,
//               "subCategoryPaymentName": "discovery"
//           },
//           {
//               "subCategoryPaymentId": 8,
//               "subCategoryPaymentName": "american express"
//           }
//       ]
//   },
//   {
//       "categoryPaymnetName": "transferencia",
//       "categoryPaymnetId": 4,
//       "subCategoryPayments": [
//           {
//               "subCategoryPaymentId": 9,
//               "subCategoryPaymentName": "transferencia"
//           },
//           {
//               "subCategoryPaymentId": 10,
//               "subCategoryPaymentName": "transferencia interbancaria"
//           }
//       ]
//   },
//   {
//       "categoryPaymnetName": "billetera móvil",
//       "categoryPaymnetId": 5,
//       "subCategoryPayments": [
//           {
//               "subCategoryPaymentId": 11,
//               "subCategoryPaymentName": "yape"
//           },
//           {
//               "subCategoryPaymentId": 12,
//               "subCategoryPaymentName": "plin"
//           }
//       ]
//   }
// ]
