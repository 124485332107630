import { useState } from "react";
import style from "./PromotionsTable.module.css";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { RowActionsPromotions } from "./RowActionsPromotions/RowActionsPromotions";
import { useNavigate } from "react-router-dom";
import { HiOutlineTicket, HiReceiptPercent } from "react-icons/hi2";
import { Promotion } from "@Models/interfaces/promotions";
import { active } from "@Models/const/promotions";
import { PackageIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";

export const PromotionsTable = ({
  items,
  handleReset,
}: {
  items: Promotion[];
  handleReset: any;
}) => {
  const [isHovered, setIsHovered] = useState<number | null>(null);
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();

  const getPromotionClass = (state: string) => {
    return state === active ? style.activePromotion : style.inactivePromotion;
  };

  const goToEditPromotion = (id: number) => {
    navigate(`/ver-paquete/${id}`);
  };

  if (!items?.length) {
    return (
      <div className={style.no_promotions}>
        <div className={style.no_promotions__icon}>
          <HiReceiptPercent size={40} />
        </div>
        <p>
          No hay resultados para tu búsqueda. ¡Intenta usar otros filtros o
          palabras!
        </p>
      </div>
    );
  }

  return (
    <section className={style.table_promotion}>
      {items.map((item) => (
        <button
          key={item.id}
          className={style.row_promotion}
          onMouseEnter={() => setIsHovered(item.id)}
          onMouseLeave={() => setIsHovered(null)}
          onClick={() => goToEditPromotion(item.id)}
        >
          <div className={style.row_info}>
            {item.typePromotion === 1 && <HiReceiptPercent size={22} />}
            {item.typePromotion === 3 && (
              <Icon path={PackageIcon} size={22} alt="Package" />
            )}
            <h3 className={style.row__info__name}>{item.name}</h3>
            {/* <p className={getPromotionClass(item.state)}>{item.state}</p> */}
            <p>
              Desde: <b>{item.startDate}</b> - Hasta: <b>{item.endDate}</b>
            </p>
          </div>
          {item.stock || item.stock === 0 ? (
            <p>
              Stock: <b>{item.stock}</b>
            </p>
          ) : (
            <p>
              <b>Ilimitado</b>
            </p>
          )}
          <RowActionsPromotions
            item={item}
            isHovered={isHovered}
            screenWidth={screenWidth}
            handleReset={handleReset}
          />
        </button>
      ))}
    </section>
  );
};
