import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemProduct } from "./promotionsFormSlice";

export interface IProductsProps {
  products: ItemProduct[] | [];
}

const initialState: IProductsProps = {
  products: [],
};

export const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    updateProducts(state, action: PayloadAction<ItemProduct[] | []>) {
      state.products = action.payload;
    },
  },
});

export default productsSlice.reducer;

export const { updateProducts } = productsSlice.actions;
