import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { useEffect, useState } from "react";
import style from "./ModalSelectServices.module.css";
// @ts-ignore
import { SearchWithColoredBorder } from "@viuti/recursos";
import {
  ItemService,
  updateSelectedServices,
} from "@ReduxService/states/promotionsFormSlice";

export const ModalSelectServices = () => {
  const dispatch = useAppDispatch();
  const services = useAppSelector((state) => state.services.services);
  const { selectedServices } = useAppSelector((state) => state.promotionForm);
  const savedServices: ItemService[] = selectedServices;
  const [search, setSearch] = useState("");
  const [filteredServices, setFilteredServices] = useState(services);

  useEffect(() => {
    setFilteredServices(
      services.filter((service: ItemService) =>
        service.serviceName.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, services]);

  const handleSelectAll = () => {
    if (savedServices.length === filteredServices.length) {
      dispatch(updateSelectedServices([]));
    } else {
      dispatch(updateSelectedServices(filteredServices));
    }
  };

  const handleCheckboxChange = (service: ItemService) => {
    if (savedServices.some((s) => s.idService === service.idService)) {
      dispatch(
        updateSelectedServices(
          savedServices.filter((s) => s.idService !== service.idService)
        )
      );
    } else {
      dispatch(updateSelectedServices([...savedServices, service]));
    }
  };

  const searchProps = {
    value: search,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearch(e.target.value),
    placeholder: "Buscar servicios...",
    setValue: setSearch,
    withoutTheContainerWithTheResults: true,
    clearInput: false,
  };

  const isServiceSelected = (id) => {
    const isSelected = savedServices.some((s) => s.idService === id);
    return isSelected;
  };

  return (
    <div className={style.modal_select_services}>
      <p>Selecciona los servicios que deseas agregar a la promoción</p>
      <SearchWithColoredBorder {...searchProps} />
      <button onClick={handleSelectAll} className={style.button_select_all}>
        {savedServices.length === filteredServices.length
          ? "Deseleccionar todos"
          : "Seleccionar todos"}
      </button>
      <div className={style.services}>
        {filteredServices.map((service) => (
          <label key={service.idService} className={style.service}>
            <input
              type="checkbox"
              id={String(service.idService)}
              name={service.serviceName}
              value={service.idService}
              checked={isServiceSelected(service.idService)}
              onChange={() => handleCheckboxChange(service)}
            />
            <span>{service.serviceName}</span>
          </label>
        ))}
      </div>
    </div>
  );
};
