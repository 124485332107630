import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type ILastView =
  // | "Promotions"
  "Packages" | "Customer categories" | "giftcards";
// | "Discount coupons";

export interface ILastViewProps {
  lastView: ILastView;
  lastViewIndex: number;
}
const initialState: ILastViewProps = {
  lastView: "Packages",
  lastViewIndex: 0,
};

export const LastViewSlice = createSlice({
  name: "LastView",
  initialState: initialState,
  reducers: {
    changeLastView(state, action: PayloadAction<ILastView>) {
      const viewIndexes = [
        // "Promotions",
        "Packages",
        "Customer categories",
        "giftcards",
        // "Discount coupons",
      ];
      state.lastView = action.payload;
      state.lastViewIndex = viewIndexes.findIndex(
        (view) => view === action.payload
      );
    },
  },
});

export default LastViewSlice.reducer;
export const { changeLastView } = LastViewSlice.actions;
