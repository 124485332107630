import { getData, loungesBaseUrl } from "@Services/constServices";
import { Lounges_mock_data } from "../../mock_data/mock_data";

export const getLounges_service = async () => {
  const url = `${loungesBaseUrl}/Lounges/Lounges`;
  const messageError =
    "Ha ocurrido un error al obtener los locales. Por favor, inténtelo de nuevo.";
  const messageSuccess = "Locales obtenidos";
  const response = await getData(url, messageSuccess, messageError);
  return response;
  // return {
  //   isSuccess: true,
  //   status: 200,
  //   message: "Locales obtenidos",
  //   data: Lounges_mock_data,
  // };
};
