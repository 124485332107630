// @ts-ignore
import { DateInput } from "@viuti/recursos";
import style from "./DateOfValidation.module.css";
import {
  updateEndDate,
  updateStartDate,
} from "@ReduxService/states/promotionsFormSlice";
import { usePromotionContext } from "@Context/promotionContext";
import { useEffect, useState } from "react";
import { isBefore, parseISO } from "date-fns";

export const DateOfValidation = () => {
  const { form, dispatch, isEdit } = usePromotionContext();
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      name === "startDate" ? updateStartDate(value) : updateEndDate(value)
    );
  };

  const handleBlur = (name) => {
    if (form[name].trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Este campo es requerido.`,
      }));
    }
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1); // Restar un día a la fecha actual
  const maxDate = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  );

  useEffect(() => {
    // Validar fecha de inicio
    if (form.startDate.trim() !== "") {
      const startDate = parseISO(form.startDate);
      if (isBefore(startDate, yesterday) && !isEdit) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          startDate: "No se puede colocar fechas antiguas.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          startDate: "",
        }));
      }
    }

    // Validar fecha de fin
    if (form.endDate.trim() !== "") {
      const endDate = parseISO(form.endDate);
      const startDate = parseISO(form.startDate);
      if (isBefore(endDate, startDate)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          endDate: "La fecha de fin no puede ser menor a la fecha de inicio.",
        }));
      } else if (isBefore(endDate, yesterday) && !isEdit) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          endDate: "No se puede colocar fechas antiguas.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          endDate: "",
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.startDate, form.endDate]);

  return (
    <div>
      <label
        className={`${style.label} ${style.title}`}
        htmlFor="vigencia-y-validez"
      >
        Vigencia y validez
      </label>
      <div className={style.dateInputContainer}>
        <DateInput
          label="Desde"
          containerStyle={{ maxWidth: 200 }}
          required
          inputProps={{
            name: "startDate",
            value: form.startDate,
            onChange: handleInputChange,
            onBlur: () => handleBlur("startDate"),
            min: today.toISOString().split("T")[0],
            max: maxDate.toISOString().split("T")[0],
          }}
          error={errors.startDate}
          touched={true}
        />
        <DateInput
          label="Hasta"
          containerStyle={{ maxWidth: 200 }}
          required
          inputProps={{
            name: "endDate",
            value: form.endDate,
            onChange: handleInputChange,
            onBlur: () => handleBlur("endDate"),
            min: today.toISOString().split("T")[0],
            max: maxDate.toISOString().split("T")[0],
          }}
          error={errors.endDate}
          touched={true}
        />
      </div>
    </div>
  );
};
