import { ModalSelectServices } from "@Components/Modals/ModalSelectServices/ModalSelectServices";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  ItemService,
  updatePriceServiceId,
  updateSavedServices,
  updateSelectedServices,
} from "@ReduxService/states/promotionsFormSlice";
import { useEffect, useState } from "react";

export const useModalSelectService = () => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { selectedServices } = useAppSelector((state) => state.promotionForm);
  const { savedServices } = useAppSelector((state) => state.promotionForm);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  };

  useEffect(() => {
    if (arraysAreEqual(selectedServices, savedServices)) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [selectedServices, savedServices]);

  const handleCancelClick = () => {
    dispatch(updateSelectedServices(savedServices));
    setIsVisible(false);
  };

  const handleSaveClick = () => {
    const updatedServices = selectedServices.map((service: ItemService) => {
      const existingService = savedServices.find(
        (saved: ItemService) => saved.idService === service.idService
      );
      return existingService
        ? { ...service, quantity: existingService.quantity }
        : { ...service, quantity: 1 };
    });
    dispatch(updatePriceServiceId(updatedServices));
    dispatch(updateSavedServices(updatedServices));
    setIsVisible(false);
  };

  const handleCloseClick = () => {
    dispatch(updateSelectedServices(savedServices));
    setIsVisible(false);
  };

  const handleOpenModalSelectServiceClick = (e) => {
    e.stopPropagation();
    setIsVisible(true);
  };

  const modalProps = {
    title: "Servicios",
    visibility: {
      isVisible: isVisible,
    },
    children: <ModalSelectServices />,
    showButtons: {
      showButtonOne: true,
      showButtonTwo: true,
      showButtonClose: true,
    },
    buttonOne: {
      textButtonOne: "Cancelar",
      actionButtonOne: handleCancelClick,
      isDisabled: false,
      isLoading: false,
    },
    buttonTwo: {
      textButtonTwo: "Guardar",
      actionButtonTwo: handleSaveClick,
      isDisabled: isButtonDisabled,
      isLoading: false,
    },
    actionButtonClose: handleCloseClick,
  };

  return { handleOpenModalSelectServiceClick, modalProps, savedServices };
};
