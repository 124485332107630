import { cashRegisterBaseUrl, getData } from "@Services/constServices";
import { PaymentMethods_mock_data } from "../../mock_data/mock_data";

export const getPaymentMethods_service = async () => {
  const url = `${cashRegisterBaseUrl}/PaymentsMethods/PaymentMethods`;
  const messageError = "Error al obtener los métodos de pago";
  const successMessage = "Métodos de pago obtenidos correctamente";
  return getData(url, successMessage, messageError);
  // return {
  //   isSuccess: true,
  //   status: 200,
  //   message: "Métodos de pago obtenidos correctamente",
  //   data: PaymentMethods_mock_data,
  // };
};
