import { IGiftcardsResponse } from "@Models/interfaces/giftcards";
import { giftcardsBaseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export const postGiftcardService = async (
  newGiftcard
): Promise<IGiftcardsResponse> => {
  const url = giftcardsBaseUrl + "/CreateGiftcard";
  const response = await axios.post(url, newGiftcard, headerAuthorization);
  return {
    isSuccess: response.status === 200,
    userMessage: response.data.message,
    data: response.data.data,
  };
};
