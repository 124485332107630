import {
  TextInput,
  SelectInput,
  // @ts-ignore
} from "@viuti/recursos";
import style from "../CreateGiftCard.module.css";

export const GiftcardFormCreation = ({
  handleInputChange,
  giftcardForm,
  giftcardFormError,
}) => {
  const period_items = [
    {
      name: "Dias",
      value: "0",
    },
    {
      name: "Meses",
      value: "1",
    },
    {
      name: "Años",
      value: "2",
    },
  ];

  return (
    <article className={style.box_container}>
      <div className={style.giftcard_dates}>
        <SelectInput
          label="Periodo"
          placeholder="Seleccionar un periodo"
          value={giftcardForm.period}
          items={period_items}
          handleChange={handleInputChange}
          name="period"
          required
        />
        <TextInput
          label={
            giftcardForm.periodSelected +
            " de vigencia " +
            (giftcardForm.periodSelected === "Meses" ? " (30 Días)" : "") +
            (giftcardForm.periodSelected === "Años" ? " (365 Días)" : "")
          }
          type="number"
          name="periodDays"
          placeholder="Ingresar los días de vigencia"
          value={giftcardForm.periodDays}
          handleChange={handleInputChange}
          className={style.input}
          id="day-giftcard"
          error={giftcardFormError.periodDays}
          touched={true}
          required
        />
      </div>
      <TextInput
        label="Monto"
        prefix="S/."
        type="number"
        name="amount"
        placeholder="Ingresar los valor del giftcard"
        value={giftcardForm.amount}
        handleChange={handleInputChange}
        error={giftcardFormError.amount}
        touched={true}
        className={style.input}
        id="price-giftcard"
        required
      />
    </article>
  );
};
