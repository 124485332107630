import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getLounges_service } from "@Services/lounges/getLounges.service";

export const getLoungesName_adapter = async (dispatch) => {
  const response = await getLounges_service();
  if (!response.isSuccess) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
    return {
      ...response,
      data: [],
    };
  }
  const adaptLounges = response.data.map((lounge) => {
    return {
      id: lounge.lounge,
      name: lounge.nameLounge,
    };
  });

  return {
    ...response,
    data: adaptLounges,
  };
};
