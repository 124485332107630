import { promotionsBaseUrl, putData } from "@Services/constServices";

export const putEditPackage_service = async (data) => {
  const url = `${promotionsBaseUrl}/Promotion/EditPromotion`;
  const successMessage = "Se actualizó el paquete correctamente.";
  const errorMessage = "No se pudo actualizar el paquete.";
  // console.log(data);
  return putData(url, data, successMessage, errorMessage);
  // return {
  //   isSuccess: true,
  //   status: 200,
  //   message: "actualizado con exito",
  //   data: {},
  // };
};
