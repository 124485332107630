import { updateNotification } from "@ReduxService/states/notificationSlice";
import { deletePromotion_service } from "@Services/promotions/deletePromotions.service";

export const deletePromotionAdapter = async (
  e,
  id,
  dispatch,
  handleCloseModalDelete,
  setIsLoading,
  handleReset
) => {
  e.stopPropagation();
  setIsLoading(true);
  const response = await deletePromotion_service(id);
  if (response.isSuccess) {
    handleCloseModalDelete(e);
    handleReset();
  } else {
    setIsLoading(false);
  }
  dispatch(
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    })
  );
};
