import {
  InfoNote,
  // @ts-ignore
} from "@viuti/recursos";
import style from "../CreateGiftCard.module.css";

export const GiftcardCreateNote = () => {
  return (
    <InfoNote type="info">
      <ul className={style.giftcard_note_ul}>
        <li>
          Tenga en cuenta que la fecha de vencimiento de la tarjeta de regalo
          comenzará a correr a partir del momento en que se utilice por primera
          vez en caja. Esto significa que los clientes tendrán un período
          específico a partir del primer uso para gastar el saldo de la tarjeta.
        </li>
        <li>
          Para subir muchos giftcards a la vez puedes hacerlo mediante el{" "}
          {/* <a href="#">creador masivo</a> */}
        </li>
      </ul>
    </InfoNote>
  );
};
