import { useModalSelectProduct } from "@Hooks/modalHooks/useModalSelectProduc";
import style from "./SelectProducts.module.css";
import { CodeBarIcon } from "@Models/icons";
// @ts-ignore
import { Icon, ModalAcceptCancel, TextInput } from "@viuti/recursos";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { formatToSoles } from "@Utilities/FormatsHandlers";
import {
  ItemProduct,
  updateLocalProductId,
  updateSavedProducts,
} from "@ReduxService/states/promotionsFormSlice";

export const SelectProducts = () => {
  const { handleOpenModalSelectProductClick, modalProps, savedProducts } =
    useModalSelectProduct();
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.products.products);
  const form = useAppSelector((state) => state.promotionForm.form);
  const message =
    form.typePromotion === "Paquete"
      ? " en este paquete."
      : " en esta promoción.";

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { name, value } = e.target;
    if (value === "0") {
      return;
    }
    // buscamos el índice del producto en el array de savedProducts
    const productIndex = savedProducts.findIndex(
      (product: ItemProduct) => product.idProduct === id
    );

    // creamos una copia del producto que queremos actualizar
    const updatedProduct = { ...savedProducts[productIndex], quantity: value };

    // creamos un nuevo array de savedProducts con el producto actualizado
    const updatedProducts = [
      ...savedProducts.slice(0, productIndex),
      updatedProduct,
      ...savedProducts.slice(productIndex + 1),
    ];
    dispatch(updateSavedProducts(updatedProducts));
    dispatch(updateLocalProductId(updatedProducts));
  };
  const total = savedProducts?.reduce(
    // @ts-ignore
    (total: number, savedProduct: ItemProduct) => {
      const product = products.find(
        (p: ItemProduct) => p.idProduct === savedProduct.idProduct
      );
      return product
        ? total + Number(product.price) * Number(savedProduct.quantity)
        : total;
    },
    0
  );

  return (
    <div>
      <label className={`${style.label} ${style.title}`} htmlFor="productos">
        <div className={style.icon}>
          <Icon path={CodeBarIcon} size={14} color="#45348e" alt="Editar" />
        </div>
        Productos (Almacén de ventas)
      </label>
      <p className={style.description}>
        Selecciona los productos de tu almacén de ventas que deseas incluir
        {message}
        <button
          className={style.button_open_products}
          onClick={handleOpenModalSelectProductClick}
        >
          dando clic aquí
        </button>
        .
      </p>
      {!!savedProducts?.length && form.typePromotion !== "Paquete" && (
        <p className={style.selected}>
          <b>
            Tienes {savedProducts.length} productos seleccionados
            {message}
          </b>
        </p>
      )}
      {!!savedProducts?.length && form.typePromotion === "Paquete" && (
        <div className={style.selected}>
          <ul className={style.productList}>
            {savedProducts.map((savedProduct) => {
              const product = products.find(
                (p: ItemProduct) => p.idProduct === savedProduct.idProduct
              );
              return product ? (
                <li key={product.idProduct} className={style.productItem}>
                  <span>{product.productName}</span>
                  <TextInput
                    label={""}
                    type="number"
                    name="quantity"
                    placeholder={"0"}
                    value={savedProduct.quantity}
                    handleChange={(e) =>
                      handleInputChange(e, savedProduct.idProduct)
                    }
                    className={style.input}
                    id="datos-basicos-productos"
                    required
                    width="50px"
                  />
                  <span className={style.price}>
                    {formatToSoles(product.price)}
                  </span>
                </li>
              ) : null;
            })}
          </ul>
          <div className={style.totalContainer}>
            <p>Total</p>
            {/* @ts-ignore */}
            <p>{formatToSoles(total)}</p>
          </div>
        </div>
      )}
      <ModalAcceptCancel {...modalProps} />
    </div>
  );
};
