import style from "./EditCustomerCategory.module.css";
import {
  HeaderNavigation,
  CriticalErrorPage,
  // @ts-ignore
} from "@viuti/recursos";
import { CustomerCategoryName } from "../Components/CustomerCategoryName/CustomerCategoryName";
import { ServicesDiscount } from "../Components/ServicesDiscount/ServicesDiscount";
import { ProductsDiscount } from "../Components/ProductsDiscount/ProductsDiscount";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { useFormCustomerCategory } from "../hooks/useFormCustomerCategory";
import { putCustomerCategory_adapter } from "@Adapters/customerCategory/putCustomerCategory.adapter";
import { useParams } from "react-router-dom";
import { useCustomerCategoryDetail } from "../hooks/useCustomerCategoryDetail";
import { Loader } from "@Components/Loader/Loader";

export const EditCustomerCategory = () => {
  const { idCategory } = useParams();
  const dispatch = useAppDispatch();
  const {
    form,
    setForm,
    error,
    setError,
    isLoading,
    setIsLoading,
    isDisabled,
    previousAction,
  } = useFormCustomerCategory();

  // Here we are using the custom hook useCustomerCategoryDetail to get the category detail
  const { formIsChanged, initialForm } = useCustomerCategoryDetail(
    idCategory,
    form,
    setForm
  );

  const handleClick = async () => {
    const response = await putCustomerCategory_adapter(
      initialForm,
      form,
      dispatch,
      setIsLoading,
      idCategory
    );
    if (response.isSuccess) {
      previousAction();
    }
  };

  const buttonProps = {
    textBttn: "Guardar",
    handleClick: handleClick,
    isDisabled: isLoading || isDisabled || formIsChanged,
    isLoading: isLoading,
    isPrimary: true,
  };

  const props = {
    form,
    setForm,
    error,
    setError,
  };

  const criticalError = useAppSelector(
    (state) => state.criticalError.criticalErrorCategories
  );

  const loader = useAppSelector((state) => state.loaderConfig.loaderCategories);

  if (loader) {
    return (
      <div id="viuti-front-mainContent">
        <HeaderNavigation
          title={"Editar categoría de cliente"}
          previousAction={previousAction}
          buttonProps={buttonProps}
        />
        <Loader />
      </div>
    );
  }

  if (criticalError) {
    return (
      <div id="viuti-front-mainContent">
        <HeaderNavigation
          title={"Editar categoría de cliente"}
          previousAction={previousAction}
          buttonProps={buttonProps}
        />
        <CriticalErrorPage />
      </div>
    );
  }

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title={"Editar categoría de cliente"}
        previousAction={previousAction}
        buttonProps={buttonProps}
      />
      <div className={style.container_customer_category}>
        <CustomerCategoryName {...props} />
        <div>
          <label
            className={`${style.label} ${style.title}`}
            htmlFor="discounts"
          >
            Descuentos
          </label>
          <p className={style.discounts}>
            Ingresa al menos un descuento que se aplicará a esta categoría.
            <span className={style.inputRequired}>*</span>
          </p>
          <div className={style.container_discounts}>
            <ServicesDiscount {...props} />
            <ProductsDiscount {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
