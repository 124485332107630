import { updateTypeDiscount } from "@ReduxService/states/promotionsFormSlice";
// @ts-ignore
import { SelectInput } from "@viuti/recursos";
import { usePromotionContext } from "@Context/promotionContext";
import { useState } from "react";
import style from "./TypeDiscount.module.css";

export const TypeDiscount = () => {
  const { form, dispatch } = usePromotionContext();
  const [error, setError] = useState("");

  const handleTypeDiscountChange = (value) => {
    dispatch(updateTypeDiscount(value));
  };

  const handleBlur = () => {
    if (!form.typeDiscount) {
      setError("Debes seleccionar un tipo de descuento");
    } else {
      setError("");
    }
  };

  const typesDiscount = [
    {
      name: "Porcentaje",
      value: "1",
    },
    {
      name: "Monto Fijo",
      value: "2",
    },
  ];

  return (
    <div>
      <label className={`${style.label} ${style.title}`} htmlFor="typeDiscount">
        Descuento
      </label>
      <SelectInput
        label="Tipo de descuento"
        placeholder="Seleccionar un tipo de descuento"
        value={form.typeDiscount}
        items={typesDiscount}
        handleChange={(e) => handleTypeDiscountChange(e.target.value)}
        handleBlur={handleBlur}
        name="typeDiscount"
        required
        error={error}
        touched
      />
    </div>
  );
};
