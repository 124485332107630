import style from "./CreateGiftCard.module.css";
import { useNavigate } from "react-router-dom";
import {
  HeaderNavigation,
  // @ts-ignore
} from "@viuti/recursos";
import { useCreationGiftcardForm } from "./hook/useCreationGiftcardForm";
import { GiftcardCreateNote } from "./components/GiftcardInfoNote.component";
import { GiftcardFormCreation } from "./components/GiftcardFormCreation.component";

export const CreateGiftcard = () => {
  const navigate = useNavigate();

  const {
    handleSubmitForm,
    handleInputChange,
    giftcardForm,
    giftcardFormError,
  } = useCreationGiftcardForm();

  const buttonProps = {
    textBttn: "Guardar",
    handleClick: () => handleSubmitForm(),
    isDisabled: false,
    isPrimary: true,
  };
  return (
    <main id="viuti-front-mainContent">
      <HeaderNavigation
        title={"Nueva Giftcard"}
        previousAction={() => navigate("/")}
        previousActionMovement={""}
        buttonProps={buttonProps}
      />
      <section className={style.giftcard_main_content}>
        {/* ----- Render the header ---- */}
        <h3>Datos del nuevo giftcard</h3>
        <p className={style.subsection}>
          Como administrador, puedes crear una o varias giftcards para los
          clientes. Esto permitirá a los clientes comprar tarjetas de regalo
          para usar en su negocio, lo que puede aumentar las ventas y atraer a
          nuevos clientes.
        </p>

        {/* ----- Render the note ---- */}
        <GiftcardCreateNote />

        {/* ----- Render the creation form ---- */}
        <GiftcardFormCreation
          handleInputChange={handleInputChange}
          giftcardForm={giftcardForm}
          giftcardFormError={giftcardFormError}
        />
      </section>
    </main>
  );
};
