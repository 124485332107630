import { getData, servicesBaseUrl } from "@Services/constServices";
// import { Services_mock_data } from "../../mock_data/mock_data";

export const getServices_service = async () => {
  const url = `${servicesBaseUrl}/GetServices`;
  const successMessage = "Se obtuvieron los servicios correctamente.";
  const errorMessage = "No se pudieron obtener los servicios.";
  return getData(url, successMessage, errorMessage);
  // return {
  //   isSuccess: true,
  //   status: 200,
  //   message: "Se obtuvieron los servicios correctamente.",
  //   data: Services_mock_data,
  // };
};
