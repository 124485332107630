import { postGiftcardAdapter } from "@Adapters/giftcards/postNewGiftcard.adapter";
import { GiftcardForm } from "@Models/interfaces/giftcards";
import { PROMOTION_ROUTE } from "@Models/routes";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useCreationGiftcardForm = () => {
  const GIFTCARD_PERIOD = ["Días", "Meses", "Años"];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Form states
  const [giftcardForm, setGiftcardForm] = useState<GiftcardForm>({
    period: 0,
    periodSelected: "Días",
    periodDays: "1",
    amount: "",
  });

  const [giftcardFormError, setGiftcardFormError] = useState({
    amount: "",
    periodDays: "",
  });

  // Handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Applied the validators
    if (name === "giftcardCode" && value.length > 10) return;

    const periodSelected =
      name === "period" ? GIFTCARD_PERIOD[value] : giftcardForm.periodSelected;

    //Update the state
    setGiftcardForm({
      ...giftcardForm,
      [name]: value,
      periodSelected,
    });
  };

  // Handle errors
  const handleSubmitForm = async () => {
    let errorObj = {
      amount: "",
      periodDays: "",
    };

    if (giftcardForm.amount.length < 1)
      errorObj.amount = "El campo de monto es obligatorio";

    if (giftcardForm.periodDays.length < 1)
      errorObj.periodDays = "El campo de días de vigencia es obligatorio";

    setGiftcardFormError(errorObj);
    if (errorObj.amount !== "" || errorObj.periodDays !== "") return;

    const adapterResponse = await postGiftcardAdapter(giftcardForm);

    dispatch(
      updateNotification({
        message: adapterResponse.message,
        status: adapterResponse.isSuccess ? 200 : 400,
        toastTimeDuration: 2000,
      })
    );

    if (adapterResponse.isSuccess) navigate(PROMOTION_ROUTE.HOME_ROUTE);
  };

  return {
    handleInputChange,
    giftcardForm,
    handleSubmitForm,
    giftcardFormError,
  };
};
