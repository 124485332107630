import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface ICriticalErrorProps {
  criticalErrorPromotions: boolean;
  criticalErrorFormPromotion: boolean;
  criticalErrorServices: boolean;
  criticalErrorCategories: boolean;
}
const initialState: ICriticalErrorProps = {
  criticalErrorPromotions: false,
  criticalErrorFormPromotion: false,
  criticalErrorServices: false,
  criticalErrorCategories: false,
};

export const criticalErrorSlice = createSlice({
  name: "criticalError",
  initialState: initialState,
  reducers: {
    updateCriticalErrorPromotions(state, action: PayloadAction<boolean>) {
      state.criticalErrorPromotions = action.payload;
    },
    updateCriticalErrorFormPromotion(state, action: PayloadAction<boolean>) {
      state.criticalErrorFormPromotion = action.payload;
    },
    updateCriticalErrorServices(state, action: PayloadAction<boolean>) {
      state.criticalErrorServices = action.payload;
    },
    updateCriticalErrorCategories(state, action: PayloadAction<boolean>) {
      state.criticalErrorCategories = action.payload;
    },
  },
});

export default criticalErrorSlice.reducer;
export const {
  updateCriticalErrorPromotions,
  updateCriticalErrorFormPromotion,
  updateCriticalErrorServices,
  updateCriticalErrorCategories,
} = criticalErrorSlice.actions;
