// @ts-ignore
import React from "react";
// Hook personalizado para gestionar el ancho de la pantalla
const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  // Función para actualizar el estado de screenWidth
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    // Establecer el ancho de pantalla inicial
    updateScreenWidth();

    // Agregar un event listener para el evento resize
    window.addEventListener("resize", updateScreenWidth);

    // Función de limpieza para remover el event listener en el desmontaje del componente
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar y desmontar el componente

  return screenWidth; // Devolver el estado actual de screenWidth
};

export default useScreenWidth;
