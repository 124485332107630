// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JZd1uwhF84PFtC04H1Hh {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & p {
    /* color: #937cf4; */
  }
}

.gzF4z_MN_3nSN1Bnok9W {
  display: flex;
  align-items: start;
}

.JMhWCq3sH8g9D0DfPfdS {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-top: 1px solid #dadada;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/ModalSelectProducts/ModalSelectProducts.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,6BAA6B;EAC7B,WAAW;EACX,eAAe;EACf;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".modal_select_services {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  & p {\n    /* color: #937cf4; */\n  }\n}\n\n.button_select_all {\n  display: flex;\n  align-items: start;\n}\n\n.product {\n  display: flex;\n  gap: 10px;\n  padding: 10px;\n  border-top: 1px solid #dadada;\n  width: 100%;\n  cursor: pointer;\n  &:hover {\n    background-color: #f5f5f5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_select_services": `JZd1uwhF84PFtC04H1Hh`,
	"button_select_all": `gzF4z_MN_3nSN1Bnok9W`,
	"product": `JMhWCq3sH8g9D0DfPfdS`
};
export default ___CSS_LOADER_EXPORT___;
