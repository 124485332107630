import style from "./SelectPaymentMethods.module.css";
// @ts-ignore
import { SelectInput, Icon, ButtonWithoutIcon } from "@viuti/recursos";
import { timesIcon } from "@Models/icons";
import { updatePaymentMethods } from "@ReduxService/states/promotionsFormSlice";
import { useEffect, useState } from "react";
import { usePromotionContext } from "@Context/promotionContext";
import { IForm } from "@Models/interfaces/formPromotions";

export const SelectPaymentMethods = ({ paymentMethods }) => {
  const { form, dispatch } = usePromotionContext();
  const [previousSelection, setPreviousSelection] = useState<
    IForm["paymentMethods"]
  >([]);
  const [error, setError] = useState<string>("");
  const [showAllMethods, setShowAllMethods] = useState<boolean>(false);

  const itemsPaymentMethods = paymentMethods.map((method) => ({
    name: method.name,
    value: String(method.id),
  }));

  const handleArrayChange = (name: keyof IForm, value: string) => {
    const selectedMethod = paymentMethods.find(
      (method) => String(method.id) === String(value)
    );
    const paymentMethodsSelected = form.paymentMethods;
    const methodExists = paymentMethodsSelected.some(
      (method) => String(method.idPaymentMethod) === String(value)
    );
    if (methodExists) {
      return;
    }
    if (selectedMethod) {
      const updatedArray = [
        ...form[name],
        {
          itemId: "",
          idPaymentMethod: selectedMethod.id,
          name: selectedMethod.name,
        },
      ];
      dispatch(updatePaymentMethods(updatedArray));
    }
  };

  const removeArrayItem = (name: keyof IForm, index: number) => {
    const updatedArray = form[name].filter((_, i) => i !== index);
    dispatch(updatePaymentMethods(updatedArray));
  };

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setPreviousSelection(form.paymentMethods);
      const allSelectedPaymentMethods = itemsPaymentMethods
        .map((item) => {
          const foundMethod = paymentMethods.find(
            (method) => String(method.id) === String(item.value)
          );
          return foundMethod
            ? {
                itemId: "",
                idPaymentMethod: foundMethod.id,
                name: foundMethod.name,
              }
            : null;
        })
        .filter(Boolean);

      dispatch(updatePaymentMethods(allSelectedPaymentMethods));
    } else {
      dispatch(updatePaymentMethods(previousSelection));
    }
  };

  const handleBlur = () => {
    if (form.paymentMethods.length === 0) {
      setError("Debes seleccionar al menos un medio de pago");
    } else {
      setError("");
    }
  };

  const toggleShowAllMethods = () => {
    setShowAllMethods(!showAllMethods); // Cambia el estado al hacer clic
  };

  useEffect(() => {
    if (form.paymentMethods.length > 0) {
      setError("");
    }
  }, [form.paymentMethods]);

  return (
    <div>
      <SelectInput
        label="Medios de pago"
        placeholder="Seleccionar un medio de pago"
        value={""}
        items={itemsPaymentMethods}
        handleChange={(e) =>
          handleArrayChange("paymentMethods", e.target.value)
        }
        handleBlur={handleBlur}
        name="paymentMethods"
        error={error}
        touched={error}
        required
      />
      <div className={style.select_all_container}>
        <input
          type="checkbox"
          name="all"
          value="all"
          id="select-all"
          className={style.select_all}
          onChange={handleSelectAllChange}
          checked={form.paymentMethods.length === itemsPaymentMethods.length}
        />
        <label
          htmlFor="select-all"
          className={`${style.label} ${style.select_all}`}
        >
          Seleccionar todos los medios de pago
        </label>
      </div>
      <div className={style.selectedMethods}>
        {form.paymentMethods
          .slice(0, showAllMethods ? form.paymentMethods.length : 6)
          .map((method, index) => (
            <div key={index} className={style.selectedMethod}>
              <span>{method.name}</span>
              <button
                type="button"
                onClick={() => removeArrayItem("paymentMethods", index)}
              >
                <Icon
                  path={timesIcon}
                  alt={"Eliminar"}
                  color={"#45348e"}
                  size={10}
                />
              </button>
            </div>
          ))}
        {form.paymentMethods.length > 6 && (
          <ButtonWithoutIcon
            handleClick={toggleShowAllMethods}
            textBttn={showAllMethods ? "Ver menos..." : "Ver más..."}
            isPrimary={false}
          />
        )}
      </div>
    </div>
  );
};
