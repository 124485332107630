import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getServices_service } from "@Services/services/getServices.service";

export const getServices_adapter = async (dispatch) => {
  const response = await getServices_service();
  if (!response.isSuccess) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
    return { ...response, data: [] };
  }

  const adaptServices = (services) => {
    return services
      .map((service) =>
        service.prices.map((price) => ({
          idService: price.idPrice,
          serviceName: `${service.nameService}: ${price.namePrice}`,
          price: price.totalPrice,
          idItem: price.idPrice,
          quantity: 1,
        }))
      )
      .flat();
  };

  return {
    ...response,
    data: adaptServices(response.data),
  };
};
