import { convertToMinutes } from "@Utilities/formatHour";
import { useEffect } from "react";

const useFormValidation = (form, initialForm, setDisabledButton, total) => {
  useEffect(() => {
    const adjustedForm = {
      ...removeWithoutLimit(form),
      stock: String(form.stock),
    };

    const adjustedInitialForm = {
      ...removeWithoutLimit(initialForm),
      stock: String(initialForm.stock),
    };

    const isDifferentFromInitial =
      JSON.stringify(adjustedForm) !== JSON.stringify(adjustedInitialForm);

    const isValidHour = () => {
      const hours = form.daysAndHoursOfAvailability?.map((day) => {
        return {
          start: convertToMinutes(day.startHour),
          end: convertToMinutes(day.endHour),
        };
      });

      return hours.every((hour) => hour.start < hour.end);
    };
    const isValidEndDate = form.endDate >= form.startDate;

    const isValidDaysAndHoursOfAvailability =
      !form.completedAvailability &&
      form.daysAndHoursOfAvailability?.length > 0 &&
      form.daysAndHoursOfAvailability.every(
        (day) => day.codeDay && day.startHour && day.endHour
      );

    const isValidPackageCost = Number(form.packageCost) <= total;

    if (
      isDifferentFromInitial &&
      form.promotionName.trim() &&
      form.startDate &&
      form.endDate &&
      isValidEndDate &&
      form.paymentMethods.length &&
      form.packageCost &&
      isValidPackageCost &&
      form.conditions.trim() &&
      (form.localProductId?.length || form?.priceServiceId.length) &&
      ((isValidDaysAndHoursOfAvailability && isValidHour()) ||
        form.completedAvailability)
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [form, initialForm, setDisabledButton, total]);
};

// Función para eliminar la propiedad "withoutLimit" de los objetos
const removeWithoutLimit = (obj) => {
  const { withoutLimit, ...rest } = obj; // Desestructuración para excluir "withoutLimit"
  return rest; // Retorna el objeto sin "withoutLimit"
};

export default useFormValidation;
