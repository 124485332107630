import React from "react";
import style from "./ServicesDiscount.module.css";
// @ts-ignore
import { TextInput } from "@viuti/recursos";

export const ServicesDiscount = ({ form, setForm, error, setError }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    setForm({ ...form, serviceDiscount: value });

    if (isNaN(value)) {
      setError({
        ...error,
        serviceDiscountError: "No está permitido ingresar textos",
      });
    } else if (value < 0 || value > 100) {
      setError({
        ...error,
        serviceDiscountError: "El descuento no puede superar el 100%",
      });
    } else {
      setError({ ...error, serviceDiscountError: "" });
    }
  };

  return (
    <div>
      <TextInput
        label="Descuento en servicios"
        type="number"
        name="serviceDiscount"
        placeholder={"Ingresar el descuento en servicios"}
        value={form.serviceDiscount}
        handleChange={handleInputChange}
        className={style.input}
        error={error.serviceDiscountError}
        touched={!!error.serviceDiscountError}
        prefix={"%"}
      />
    </div>
  );
};
