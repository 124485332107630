import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useFormCustomerCategory = () => {
  const navigation = useNavigate();
  const [form, setForm] = useState({
    customerCategoryName: "",
    serviceDiscount: 0,
    productsDiscount: 0,
  });

  const [error, setError] = useState({
    customerCategoryNameError: "",
    serviceDiscountError: 0,
    productsDiscountError: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      form.customerCategoryName.trim() &&
      !error.serviceDiscountError &&
      !error.productsDiscountError
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [form, error]);

  const previousAction = () => {
    navigation("/");
  };

  return {
    form,
    setForm,
    error,
    setError,
    isLoading,
    setIsLoading,
    isDisabled,
    previousAction,
  };
};
