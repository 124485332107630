import { Route, Routes } from "react-router-dom";
import {
  CUSTOMER_CATEGORY_ROUTE,
  GIFTCARD_ROUTE,
  PROMOTION_ROUTE,
} from "@Models/routes";
import { MainView } from "@Pages/MainView";
import { PromotionForm } from "@Pages/Promotions/PromotionForm/PromotionForm";
import { CreateGiftcard } from "@Pages/Giftcards/CreateGiftCard/CreateGiftCard";
// @ts-ignore
import { NotFoundPage } from "@viuti/recursos";
import CreateMassiveGiftcards from "@Pages/Giftcards/CreateMassiveGiftcards/CreateMassiveGiftcards";
import { CreateCustomerCategory } from "@Pages/CustommerCategories/CreateCustomerCategory/CreateCustomerCategory";
import { EditCustomerCategory } from "@Pages/CustommerCategories/EditCustomerCategory/EditCustomerCategory";

const ContextRoute = () => {
  return (
    <Routes>
      {/* ---- PROMOTION ROUTES ------ */}
      <Route path={PROMOTION_ROUTE.HOME_ROUTE} element={<MainView />} />
      <Route
        path={PROMOTION_ROUTE.CREATE_PROMOTION}
        element={<PromotionForm isEdit={false} />}
      />
      <Route
        path={PROMOTION_ROUTE.EDIT_PROMOTION}
        element={<PromotionForm isEdit={true} />}
      />
      <Route
        path={CUSTOMER_CATEGORY_ROUTE.CREATE_CUSTOMER_CATEGORY}
        element={<CreateCustomerCategory />}
      />
      <Route
        path={CUSTOMER_CATEGORY_ROUTE.EDIT_CUSTOMER_CATEGORY}
        element={<EditCustomerCategory />}
      />

      {/* ---- GIFTCARDS ROUTES ------ */}
      <Route
        path={GIFTCARD_ROUTE.CREATE_GIFTCARD}
        element={<CreateGiftcard />}
      />
      <Route
        path={GIFTCARD_ROUTE.CREATE_MASSIVE_GIFTCARDS}
        element={<CreateMassiveGiftcards />}
      />

      {/* ---- GENERAL ROUTES ------ */}
      <Route path="*" element={<NotFoundPage />}></Route>
    </Routes>
  );
};

export default ContextRoute;
