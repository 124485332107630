import { createContext, useContext } from "react";

const PromotionContext = createContext({
  form: {},
  dispatch: () => {},
  isEdit: false,
});

export const PromotionProvider = ({ form, dispatch, children, isEdit }) => {
  return (
    <PromotionContext.Provider value={{ form, dispatch, isEdit }}>
      {children}
    </PromotionContext.Provider>
  );
};

export const usePromotionContext = () => useContext(PromotionContext);
