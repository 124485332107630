// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oLHxtzy5oXZyZaFcAXz4 {
  margin-top: 24px;
}

.CO0AFfyBEQhxwGDgkMYe {
  display: flex;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-label);
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.r3EwCAu_6Cfj1i7p5v5i {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.233px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.IH4nrTIR8ftW2gELW1d8 {
  margin-bottom: 8px;
}

.pTxBJte8kjNei9GBQtEE {
  font-family: "Mulish", sans-serif;
  color: red;
  font-size: 12px;
  margin-left: 3px;
}

.pDraEjcpaMkizn2wOV0I {
  display: grid;
  gap: 16px;
  margin-top: 16px;
}

.v3DDSZzilw748QlwYcVv {
  width: 100%;
  max-height: -webkit-fill-available;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #937cf4;
  background: #fff;
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/CustommerCategories/EditCustomerCategory/EditCustomerCategory.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iCAAiC;EACjC,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kCAAkC;EAClC,aAAa;EACb,qCAAqC;EACrC,aAAa;EACb,SAAS;EACT,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".container_customer_category {\n  margin-top: 24px;\n}\n\n.label {\n  display: flex;\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-label);\n  font-style: normal;\n  font-weight: 500;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.title {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.233px;\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.discounts {\n  margin-bottom: 8px;\n}\n\n.inputRequired {\n  font-family: \"Mulish\", sans-serif;\n  color: red;\n  font-size: 12px;\n  margin-left: 3px;\n}\n\n.sectionContainer {\n  display: grid;\n  gap: 16px;\n  margin-top: 16px;\n}\n\n.container_discounts {\n  width: 100%;\n  max-height: -webkit-fill-available;\n  display: grid;\n  grid-template-columns: auto auto auto;\n  padding: 24px;\n  gap: 24px;\n  border-radius: 8px;\n  border: 1px solid #937cf4;\n  background: #fff;\n  @media screen and (max-width: 500px) {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_customer_category": `oLHxtzy5oXZyZaFcAXz4`,
	"label": `CO0AFfyBEQhxwGDgkMYe`,
	"title": `r3EwCAu_6Cfj1i7p5v5i`,
	"discounts": `IH4nrTIR8ftW2gELW1d8`,
	"inputRequired": `pTxBJte8kjNei9GBQtEE`,
	"sectionContainer": `pDraEjcpaMkizn2wOV0I`,
	"container_discounts": `v3DDSZzilw748QlwYcVv`
};
export default ___CSS_LOADER_EXPORT___;
