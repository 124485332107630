import { updateNotification } from "@ReduxService/states/notificationSlice";
import { putCustomerCategory_service } from "@Services/customerCategory/putCustomerCategory.service";

export const putCustomerCategory_adapter = async (
  initialData,
  data: any,
  dispatch,
  setIsLoading,
  id
) => {
  setIsLoading(true);

  // Trimming and comparing fields
  const trimmedData = {
    customerCategoryName: data.customerCategoryName.trim(),
    serviceDiscount: data.serviceDiscount,
    productsDiscount: data.productsDiscount,
  };

  // Define adaptData as a flexible object
  const adaptData: { categoryClientId: number } & Record<string, any> = {
    categoryClientId: Number(id),
  };

  if (
    initialData.customerCategoryName.trim() !==
    trimmedData.customerCategoryName.trim()
  ) {
    adaptData.categoryName = trimmedData.customerCategoryName;
  }
  if (initialData.serviceDiscount !== trimmedData.serviceDiscount) {
    adaptData.serviceDiscountPercentage = Number(trimmedData.serviceDiscount);
  }
  if (initialData.productsDiscount !== trimmedData.productsDiscount) {
    adaptData.productDiscountPercentage = Number(trimmedData.productsDiscount);
  }

  const response = await putCustomerCategory_service(adaptData);
  setIsLoading(false);

  dispatch(
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    })
  );

  return response;
};
