import { promotionsBaseUrl, deleteData } from "@Services/constServices";

export const deletePromotion_service = async (id) => {
  const url = `${promotionsBaseUrl}/Promotion/DeletePromotion?promotionId=${id}`;
  const successMessage = "Promoción eliminada exitosamente.";
  const errorMessage = "No se pudo eliminar la promoción. Intente de nuevo.";
  return deleteData(url, successMessage, errorMessage);
  // return {
  //   isSuccess: true,
  //   status: 200,
  //   data: null,
  //   message: "Promoción eliminada exitosamente.",
  // };
};
