import { updateDiscount } from "@ReduxService/states/promotionsFormSlice";
import style from "../../PromotionForm.module.css";
// @ts-ignore
import { TextInput } from "@viuti/recursos";
import { usePromotionContext } from "@Context/promotionContext";
import { useEffect, useState } from "react";
import { formatToSoles } from "@Utilities/FormatsHandler";

export const PromotionDiscount = () => {
  const { form, dispatch } = usePromotionContext();
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { value } = e.target;
    dispatch(updateDiscount(value));
  };

  useEffect(() => {
    dispatch(updateDiscount(""));
    // eslint-disable-next-line
  }, [form.typeDiscount]);

  const handleBlur = () => {
    const validateDiscount = () => {
      if (!form.discount) {
        return "Este campo es requerido";
      }

      const regex = /^[0-9]+([.,][0-9]+)?$/;
      if (!regex.test(form.discount)) {
        return "El descuento solo puede tener valores numéricos";
      }

      if (form.typeDiscount === "1" && Number(form.discount) > 100) {
        return "El descuento no puede ser mayor al 100%";
      }

      return "";
    };

    const errorMessage = validateDiscount();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    setError("");
    if (form.typeDiscount === "2") {
      const format = formatToSoles(form.discount, false);
      dispatch(updateDiscount(format));
    }
  };

  const prefix = () => {
    if (form.typeDiscount === "1") {
      return "%";
    }
    if (form.typeDiscount === "2") {
      return "S/";
    }
    return "";
  };

  const placeholder = () => {
    if (form.typeDiscount === "1") {
      return "Descuento";
    }
    if (form.typeDiscount === "2") {
      return "Descuento";
    }
    return "Primero selecciona un tipo de descuento";
  };

  return (
    <div>
      <TextInput
        type="number"
        name="discount"
        placeholder={placeholder()}
        value={form.discount}
        handleChange={handleInputChange}
        handleBlur={handleBlur}
        className={style.input}
        required
        error={error}
        touched={error}
        prefix={prefix()}
        disabled={!form.typeDiscount}
      />
    </div>
  );
};
