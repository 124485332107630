import {
  updateCompletedAvailability,
  updatedaysAndHoursOfAvailability,
} from "@ReduxService/states/promotionsFormSlice";
import { DayAvailability } from "../DayAvailability/DayAvailability";
import style from "./Availability.module.css";
// @ts-ignore
import { FormAddMore } from "@viuti/recursos";
import { usePromotionContext } from "@Context/promotionContext";
import { useEffect, useState } from "react";

export const Availability = () => {
  const { form, dispatch, isEdit } = usePromotionContext();
  const [noRestrictions, setNoRestrictions] = useState(false); // Estado para manejar el checkbox

  // Nueva función para manejar el cambio del checkbox
  const handleNoRestrictionsChange = (event) => {
    const isChecked = event.target.checked;
    setNoRestrictions(isChecked); // Actualiza el estado según el checkbox
    dispatch(updateCompletedAvailability(isChecked));
    if (isEdit) {
      if (isChecked) {
        dispatch(updatedaysAndHoursOfAvailability([]));
      } else {
        dispatch(
          updatedaysAndHoursOfAvailability([
            {
              codeDay: "",
              startHour: "",
              endHour: "",
            },
          ])
        );
      }
    } else {
      dispatch(
        updatedaysAndHoursOfAvailability([
          {
            codeDay: "",
            startHour: "",
            endHour: "",
          },
        ])
      );
    }
  };

  useEffect(() => {
    setNoRestrictions(form.completedAvailability);
    handleNoRestrictionsChange({
      target: { checked: form.completedAvailability },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.completedAvailability]);

  const handleDayChange = (index, updates) => {
    // Actualiza los días y horas de disponibilidad con los nuevos valores
    const updatedDays = form.daysAndHoursOfAvailability?.map((day, i) =>
      i === index ? { ...day, ...updates } : day
    );
    dispatch(updatedaysAndHoursOfAvailability(updatedDays));
  };

  const addDayAvailability = () => {
    const newDay = { codeDay: "", startHour: "", endHour: "" };
    const updatedDays = [...form.daysAndHoursOfAvailability, newDay];
    dispatch(updatedaysAndHoursOfAvailability(updatedDays));
  };

  const removeDayAvailability = (index) => {
    const updatedDays = form.daysAndHoursOfAvailability.filter(
      (_, i) => i !== index
    );
    dispatch(updatedaysAndHoursOfAvailability(updatedDays));
  };

  return (
    <div>
      <label
        className={`${style.label} ${style.title}`}
        htmlFor="disponibilidad"
      >
        Disponibilidad
      </label>
      <div className={style.select_all_container}>
        <input
          type="checkbox"
          id="no-restrictions"
          name="no-restrictions"
          value={form.completedAvailability}
          checked={form.completedAvailability}
          onChange={handleNoRestrictionsChange}
          className={style.select_all}
        />
        <label
          htmlFor="no-restrictions"
          className={`${style.labelCheckbox} ${style.select_all}`}
        >
          Disponibilidad completa
        </label>
      </div>
      {!noRestrictions && (
        <FormAddMore
          buttonText="Agregar"
          childrens={form.daysAndHoursOfAvailability?.map((day, index) => (
            <DayAvailability
              key={index}
              day={day}
              index={index}
              handleDayChange={handleDayChange}
              removeDayAvailability={removeDayAvailability}
              form={form}
              isSingle={form.daysAndHoursOfAvailability.length === 1}
            />
          ))}
          buttonAction={addDayAvailability}
        />
      )}
    </div>
  );
};
