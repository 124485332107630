import { ModalSelectProducts } from "@Components/Modals/ModalSelectProducts/ModalSelectProducts";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  ItemProduct,
  updateLocalProductId,
  updateSavedProducts,
  updateSelectedProducts,
} from "@ReduxService/states/promotionsFormSlice";
import { useEffect, useState } from "react";

export const useModalSelectProduct = () => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { selectedProducts } = useAppSelector((state) => state.promotionForm);
  const { savedProducts } = useAppSelector((state) => state.promotionForm);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  };

  useEffect(() => {
    if (arraysAreEqual(selectedProducts, savedProducts)) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [selectedProducts, savedProducts]);

  const handleCancelClick = () => {
    dispatch(updateSelectedProducts(savedProducts));
    setIsVisible(false);
  };

  const handleSaveClick = () => {
    const updatedProducts = selectedProducts.map((product: ItemProduct) => {
      const existingProduct = savedProducts.find(
        (saved: ItemProduct) => saved.idProduct === product.idProduct
      );
      return existingProduct
        ? { ...product, quantity: existingProduct.quantity }
        : { ...product, quantity: 1 };
    });

    dispatch(updateLocalProductId(updatedProducts));
    dispatch(updateSavedProducts(updatedProducts));
    setIsVisible(false);
  };

  const handleCloseClick = () => {
    dispatch(updateSelectedProducts(savedProducts));
    setIsVisible(false);
  };

  const handleOpenModalSelectProductClick = (e) => {
    e.stopPropagation();
    setIsVisible(true);
  };

  const modalProps = {
    title: "Productos",
    visibility: {
      isVisible: isVisible,
    },
    children: <ModalSelectProducts />,
    showButtons: {
      showButtonOne: true,
      showButtonTwo: true,
      showButtonClose: true,
    },
    buttonOne: {
      textButtonOne: "Cancelar",
      actionButtonOne: handleCancelClick,
      isDisabled: false,
      isLoading: false,
    },
    buttonTwo: {
      textButtonTwo: "Guardar",
      actionButtonTwo: handleSaveClick,
      isDisabled: isButtonDisabled,
      isLoading: false,
    },
    actionButtonClose: handleCloseClick,
  };

  return { handleOpenModalSelectProductClick, modalProps, savedProducts };
};
