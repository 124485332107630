import style from "./DeletePromotion.module.css";
import { WarningTriangleIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";

export const DeletePromotion = ({
  title = "¿Estás seguro de que deseas eliminar la promoción?",
}) => {
  return (
    <div className={style.form_container}>
      <figure>
        <Icon path={WarningTriangleIcon} alt="Simbolo de advertencia" />
      </figure>
      <h3 className={style.warning_message}>
        {title}
        <br />
        Ten en cuenta que esta acción no se puede deshacer.
      </h3>
    </div>
  );
};
