import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useModifyButtonPromotions = (setButtonProps) => {
  const navigation = useNavigate();
  useEffect(() => {
    setButtonProps({
      textBttn: "Nuevo paquete",
      handleClick: () => navigation("/nuevo-paquete"),
      isDisabled: false,
      isPrimary: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
