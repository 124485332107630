import { getPromotionsAdapter } from "@Adapters/promotions/getPromotions.adapter";
import { useAppDispatch } from "@Hooks/store";
import {
  updateCurrentPage,
  updatePromotions,
  updateTotalPages,
} from "@ReduxService/states/promotionsSlice";
import { ApiResponse } from "@Services/constServices";
import { useEffect } from "react";

export const useUpdatePromotionsInRedux = (currentPagination: number) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      const response: ApiResponse = await getPromotionsAdapter(
        dispatch,
        currentPagination
      );
      dispatch(updatePromotions(response.data.promotions));
      dispatch(updateCurrentPage(response.data.currentPage));
      dispatch(updateTotalPages(response.data.totalPages));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPagination]);
};
