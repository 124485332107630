import { getPaymentMethods } from "@Adapters/paymentMethods/getPaymentMethods.adapter";
import { getServices_adapter } from "@Adapters/services/getServices.adapter";
import { updateservices } from "@ReduxService/states/servicesSlice";
import { updateCriticalErrorFormPromotion } from "@ReduxService/states/criticalErrorSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLoaderFormPromotion } from "@ReduxService/states/loaderSlice";
import { getProducts_adapter } from "@Adapters/products/getProducts.adapter";
import { updateProducts } from "@ReduxService/states/productsSlice";
import { getLoungesName_adapter } from "@Adapters/loungesName/getLoungesName.adapter";

export const useFormPromotion = (isEdit) => {
  const [lounges, setLounges] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const [
        responseProducts,
        responseServices,
        responseLounges,
        responsePaymentMethods,
      ] = await Promise.all([
        getProducts_adapter(dispatch),
        getServices_adapter(dispatch),
        getLoungesName_adapter(dispatch),
        getPaymentMethods(dispatch),
      ]);
      if (!isEdit) {
        dispatch(updateLoaderFormPromotion(false));
      }
      if (
        !responseProducts.isSuccess ||
        !responseServices.isSuccess ||
        !responseLounges.isSuccess ||
        !responsePaymentMethods.isSuccess
      ) {
        dispatch(updateCriticalErrorFormPromotion(true));
        return;
      }
      dispatch(updateProducts(responseProducts.data));
      dispatch(updateservices(responseServices.data));
      setLounges(responseLounges.data);
      setPaymentMethods(responsePaymentMethods.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return {
    lounges,
    paymentMethods,
  };
};
