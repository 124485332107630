import { getPromotions_service } from "@Services/promotions/getPromotions.service";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { updateCriticalErrorPromotions } from "@ReduxService/states/criticalErrorSlice";
import { updateLoaderPromotions } from "@ReduxService/states/loaderSlice";
import { Promotion } from "@Models/interfaces/promotions";
import { convertDate } from "@Utilities/convertDate";

export const getPromotionsAdapter = async (dispatch, page) => {
  dispatch(updateLoaderPromotions(true));
  const response = await getPromotions_service({ page });
  dispatch(updateLoaderPromotions(false));

  if (!response.isSuccess) {
    dispatch(updateCriticalErrorPromotions(true));
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
    return response;
  }

  const adaptPromotion = (promotion, index) => {
    return {
      id: promotion.promotionId,
      name: promotion.promotionName,
      state: promotion.estate,
      startDate: convertDate(promotion.validFrom),
      endDate: convertDate(promotion.validUntil),
      stock: promotion.stock,
      typePromotion: promotion.typePromotion.typePromotionId,
    };
  };

  const promotions: Promotion[] = response.data.promotion.map(adaptPromotion);
  const currentPage: number = response.data.actualPage;
  const totalPages: number = response.data.maxPage;

  return {
    ...response,
    data: {
      promotions,
      currentPage,
      totalPages,
    },
  };
};
