import { postData, promotionsBaseUrl } from "@Services/constServices";

export const postCustomerCategory_service = async (data: any) => {
  const url = `${promotionsBaseUrl}/CategoryClient/CreateCategoryClient`;
  const successMessage = "Categoría creada correctamente";
  const errorMessage = "Error al crear la categoría de cliente";
  return await postData(url, data, successMessage, errorMessage);
  // return {
  //   isSucces: true,
  //   message: "Categoría de cliente creada correctamente",
  //   data: {
  //     id: 1,
  //     ...data,
  //   },
  //   status: 200,
  // };
};
