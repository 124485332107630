import { getData, promotionsBaseUrl } from "@Services/constServices";
import { CustomerCategory_mock_data } from "../../../src/mock_data/mock_data";

export const getCustomerCategory_service = async () => {
  const url = `${promotionsBaseUrl}/CategoryClient/GetCategoryClient`;
  const successMessage = "Categorías de clientes obtenidas correctamente";
  const errorMessage = "Error al obtener las categorías de clientes";
  return getData(url, successMessage, errorMessage);
  // return {
  //   isSuccess: true,
  //   status: 200,
  //   message: "Categorías de clientes obtenidas correctamente",
  //   data: CustomerCategory_mock_data,
  // };
};
