import {
  HeaderNavigation,
  TabNavigation,
  PaginationWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import { PromotionList } from "./Promotions/PromotionList/PromotionList";
import { useEffect, useState } from "react";
import { ButtonProps } from "@Models/interfaces/props";
import GiftcardList from "./Giftcards/GiftCardList/GiftCardList";
import { CustomerCategoryList } from "./CustommerCategories/CustomerCategoryList/CustomerCategoryList";
import { useAppSelector } from "@Hooks/store";
import style from "./MainView.module.css";
import { useUpdatePromotionsInRedux } from "@Hooks/usePromotions/useUpdatePromotionsInRedux";

export const MainView = () => {
  const lastViewReduxContext = useAppSelector((state) => state.lastView);

  const [buttonProps, setButtonProps] = useState<ButtonProps>({
    textBttn: "Nuevo paquete",
    handleClick: () => {},
    isDisabled: false,
    isPrimary: true,
  });

  // Función para resetear todos los estados a sus valores iniciales
  const handleReset = () => {
    setCurrentPagination(1);
  };

  const [currentPagination, setCurrentPagination] = useState(1);
  const totalPages = useAppSelector((state) => state.promotions.totalPages);
  const itemsPerPage = 10;
  useUpdatePromotionsInRedux(currentPagination);

  const navigationViews = [
    {
      name: "Paquetes",
      component: (
        <PromotionList
          setButtonProps={setButtonProps}
          handleReset={handleReset}
        />
      ),
    },
    // {
    //   name: "Categoría de clientes",
    //   component: <CustomerCategoryList setButtonProps={setButtonProps} />,
    // },
    // {
    //   name: "Giftcards",
    //   component: <GiftcardList setButtonProps={setButtonProps} handleReset={handleReset} />,
    // },
  ];

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title={"Marketing"}
        previousAction={false}
        previousActionMovement={""}
        buttonProps={buttonProps}
      />
      <TabNavigation
        views={navigationViews}
        initialView={lastViewReduxContext.lastViewIndex}
      />
      {totalPages > 1 && (
        <div className={style.pagination_wrapper}>
          <PaginationWrapper
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            optionsPerPage={itemsPerPage}
            numberOfButtons={totalPages}
          />
        </div>
      )}
    </div>
  );
};
