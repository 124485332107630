import { IForm } from "@Models/interfaces/formPromotions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemProduct, ItemService } from "./promotionsFormSlice";

export interface PromotionInitialForm {
  initialForm: IForm;
  savedServices: ItemService[];
  savedProducts: ItemProduct[];
  selectedServices: ItemService[];
  selectedProducts: ItemProduct[];
}

const today = new Date();
const todayString = today.toISOString().split("T")[0];

const initialState: PromotionInitialForm = {
  initialForm: {
    typePromotion: "Paquete",
    promotionName: "",
    startDate: todayString,
    endDate: "",
    completedAvailability: false,
    daysAndHoursOfAvailability: [
      {
        itemId: "",
        codeDay: "",
        startHour: "",
        endHour: "",
      },
    ],
    codePromotion: "",
    stock: "",
    withoutLimit: false,
    paymentMethods: [],
    discount: "",
    typeDiscount: "",
    priceServiceId: [],
    localProductId: [],
    packageCost: "",
    conditions: "",
    localId: [],
    // careChannels: "",
  },
  savedServices: [],
  selectedServices: [],
  savedProducts: [],
  selectedProducts: [],
};

export const promotionInitialFormSlice = createSlice({
  name: "promotionInitialForm",
  initialState: initialState,
  reducers: {
    updateInitialFormTypePromotion(
      state,
      action: PayloadAction<"Descuento" | "Cupón de descuento" | "Paquete" | "">
    ) {
      state.initialForm.typePromotion = action.payload;
    },
    updateInitialFormPromotionName(state, action: PayloadAction<string>) {
      state.initialForm.promotionName = action.payload;
    },
    updateInitialFormStartDate(state, action: PayloadAction<string>) {
      state.initialForm.startDate = action.payload;
    },
    updateInitialFormEndDate(state, action: PayloadAction<string>) {
      state.initialForm.endDate = action.payload;
    },
    updateInitialFormCompletedAvailability(
      state,
      action: PayloadAction<boolean>
    ) {
      state.initialForm.completedAvailability = action.payload;
    },
    updateInitialFormdaysAndHoursOfAvailability(
      state,
      action: PayloadAction<any>
    ) {
      state.initialForm.daysAndHoursOfAvailability = action.payload;
    },
    updateInitialFormCodePromotion(state, action: PayloadAction<string>) {
      state.initialForm.codePromotion = action.payload;
    },
    updateInitialFormStock(state, action: PayloadAction<string>) {
      state.initialForm.stock = action.payload;
    },
    updateInitialFormWithoutLimit(state, action: PayloadAction<boolean>) {
      state.initialForm.withoutLimit = action.payload;
    },
    updateInitialFormPaymentMethods(state, action: PayloadAction<any>) {
      state.initialForm.paymentMethods = action.payload;
    },
    updateInitialFormDiscount(state, action: PayloadAction<string>) {
      state.initialForm.discount = action.payload;
    },
    updateInitialFormPackageCost(state, action: PayloadAction<string>) {
      state.initialForm.packageCost = action.payload;
    },
    updateInitialFormTypeDiscount(state, action: PayloadAction<string>) {
      state.initialForm.typeDiscount = action.payload;
    },
    updateInitialFormPriceServiceId(state, action: PayloadAction<any>) {
      state.initialForm.priceServiceId = action.payload;
    },
    updateInitialFormLocalProductId(state, action: PayloadAction<any>) {
      state.initialForm.localProductId = action.payload;
    },
    updateInitialFormConditions(state, action: PayloadAction<string>) {
      state.initialForm.conditions = action.payload;
    },
    updateInitialFormLocalId(state, action: PayloadAction<any>) {
      state.initialForm.localId = action.payload;
    },
    // updateInitialFormCareChannels(state, action: PayloadAction<string>) {
    //   state.initialForm.careChannels = action.payload;
    // },
    updateInitialFormSelectedServices(state, action: PayloadAction<any>) {
      state.selectedServices = action.payload;
    },
    updateInitialFormSavedServices(state, action: PayloadAction<any>) {
      state.savedServices = action.payload;
    },
    updateInitialFormSelectedProducts(state, action: PayloadAction<any>) {
      state.selectedProducts = action.payload;
    },
    updateInitialFormSavedProducts(state, action: PayloadAction<any>) {
      state.savedProducts = action.payload;
    },
    updateInitialFormQuantitySavedProducts(state, action: PayloadAction<any>) {
      state.savedProducts = action.payload;
    },
    setInitialForm(state, action: PayloadAction<any>) {
      state.initialForm = action.payload.form;
      state.savedServices = action.payload.savedServices;
      state.selectedServices = action.payload.selectedServices;
      state.savedProducts = action.payload.savedProducts;
      state.selectedProducts = action.payload.selectedProducts;
    },
    updateInitialForm(state, action: PayloadAction<any>) {
      state.initialForm = action.payload;
    },
    clearInitialForm(state) {
      state.initialForm = {
        typePromotion: "Paquete",
        promotionName: "",
        startDate: todayString,
        endDate: "",
        completedAvailability: false,
        daysAndHoursOfAvailability: [
          {
            itemId: "",
            codeDay: "",
            startHour: "",
            endHour: "",
          },
        ],
        codePromotion: "",
        stock: "",
        withoutLimit: false,
        paymentMethods: [],
        discount: "",
        typeDiscount: "",
        priceServiceId: [],
        localProductId: [],
        packageCost: "",
        conditions: "",
        localId: [],
      };
      state.savedServices = [];
      state.selectedServices = [];
      state.savedProducts = [];
      state.selectedProducts = [];
    },
  },
});

export default promotionInitialFormSlice.reducer;

export const {
  updateInitialFormTypePromotion,
  updateInitialFormPromotionName,
  updateInitialFormStartDate,
  updateInitialFormEndDate,
  updateInitialFormCompletedAvailability,
  updateInitialFormdaysAndHoursOfAvailability,
  updateInitialFormCodePromotion,
  updateInitialFormStock,
  updateInitialFormWithoutLimit,
  updateInitialFormPaymentMethods,
  updateInitialFormDiscount,
  updateInitialFormTypeDiscount,
  updateInitialFormPriceServiceId,
  updateInitialFormPackageCost,
  updateInitialFormLocalProductId,
  updateInitialFormConditions,
  updateInitialFormLocalId,
  // updateInitialFormCareChannels,
  updateInitialFormSelectedServices,
  updateInitialFormSavedServices,
  updateInitialFormSelectedProducts,
  updateInitialFormSavedProducts,
  updateInitialFormQuantitySavedProducts,
  setInitialForm,
  updateInitialForm,
  clearInitialForm,
} = promotionInitialFormSlice.actions;
