// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kh6_Zgi2VFOKZA9vwPGU {
  display: flex;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-label);
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gJw4hL9nTOHfzt1mZW41 {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.233px;
  margin-top: 16px;
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Promotions/PromotionForm/components/PackageCost/CreatePackage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;EACjC,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".label {\n  display: flex;\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-label);\n  font-style: normal;\n  font-weight: 500;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.title {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.233px;\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `Kh6_Zgi2VFOKZA9vwPGU`,
	"title": `gJw4hL9nTOHfzt1mZW41`
};
export default ___CSS_LOADER_EXPORT___;
