import { promotionsBaseUrl, getData } from "@Services/constServices";
import { Promotions_mock_data } from "../../mock_data/mock_data";

export const getPromotions_service = async ({ page = 1 }) => {
  const url = `${promotionsBaseUrl}/Promotion/GetPromotion?page=${page}`;
  const successMessage = "Se obtuvieron las promociones correctamente.";
  const errorMessage = "No se pudieron obtener las promociones.";
  return getData(url, successMessage, errorMessage);
  // return {
  //   isSuccess: true,
  //   status: 200,
  //   message: "Se obtuvieron las promociones correctamente.",
  //   data: Promotions_mock_data,
  // };
};
