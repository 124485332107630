// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K3KPZPlp4rx43Ksggved {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd","sourcesContent":[".loadingIcon {\n  display: grid;\n  place-items: center;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingIcon": `K3KPZPlp4rx43Ksggved`
};
export default ___CSS_LOADER_EXPORT___;
