// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DmCP6TaLilLPXPjxnIZp {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 28px;
}

.DmCP6TaLilLPXPjxnIZp h3 {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.233px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.DmCP6TaLilLPXPjxnIZp .N0VXUc8HeuK71HEMtsam {
  margin-bottom: 8px;
}

.BDpCQJlnsjoRvRPhzVUt {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
}

.diIiwLj8UgIpYuUXXym0 {
  width: 100%;
  max-height: -webkit-fill-available;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #937cf4;
  background: #fff;
}

.gcStN1wo5b5gJUyLXTmB {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (width <= 830px) {
  .diIiwLj8UgIpYuUXXym0 {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (width <= 460px) {
  .BDpCQJlnsjoRvRPhzVUt {
    display: flex;
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Giftcards/CreateGiftCard/CreateGiftCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,wDAAwD;EACxD,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kCAAkC;EAClC,aAAa;EACb,qCAAqC;EACrC,aAAa;EACb,SAAS;EACT,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;AACF","sourcesContent":[".giftcard_main_content {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-top: 28px;\n}\n\n.giftcard_main_content h3 {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.233px;\n  margin-top: 16px;\n  margin-bottom: 8px;\n}\n\n.giftcard_main_content .subsection {\n  margin-bottom: 8px;\n}\n\n.giftcard_dates {\n  display: grid;\n  grid-template-columns: calc(50% - 10px) calc(50% - 10px);\n  gap: 20px;\n}\n\n.box_container {\n  width: 100%;\n  max-height: -webkit-fill-available;\n  display: grid;\n  grid-template-columns: auto auto auto;\n  padding: 24px;\n  gap: 24px;\n  border-radius: 8px;\n  border: 1px solid #937cf4;\n  background: #fff;\n}\n\n.giftcard_note_ul {\n  margin-left: 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n@media screen and (width <= 830px) {\n  .box_container {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n@media screen and (width <= 460px) {\n  .giftcard_dates {\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"giftcard_main_content": `DmCP6TaLilLPXPjxnIZp`,
	"subsection": `N0VXUc8HeuK71HEMtsam`,
	"giftcard_dates": `BDpCQJlnsjoRvRPhzVUt`,
	"box_container": `diIiwLj8UgIpYuUXXym0`,
	"giftcard_note_ul": `gcStN1wo5b5gJUyLXTmB`
};
export default ___CSS_LOADER_EXPORT___;
