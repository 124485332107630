import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ItemService } from "./promotionsFormSlice";

export interface IServicesProps {
  services: ItemService[] | [];
}

const initialState: IServicesProps = {
  services: [],
};

export const servicesSlice = createSlice({
  name: "services",
  initialState: initialState,
  reducers: {
    updateservices(state, action: PayloadAction<ItemService[] | []>) {
      state.services = action.payload;
    },
  },
});

export default servicesSlice.reducer;
export const { updateservices } = servicesSlice.actions;
