import style from "./CreateCustomerCategory.module.css";
import {
  HeaderNavigation,
  // @ts-ignore
} from "@viuti/recursos";
import { CustomerCategoryName } from "../Components/CustomerCategoryName/CustomerCategoryName";
import { ServicesDiscount } from "../Components/ServicesDiscount/ServicesDiscount";
import { ProductsDiscount } from "../Components/ProductsDiscount/ProductsDiscount";
import { postCustomerCategory_adapter } from "@Adapters/customerCategory/postCustomerCategory.adapter";
import { useAppDispatch } from "@Hooks/store";
import { useFormCustomerCategory } from "../hooks/useFormCustomerCategory";

export const CreateCustomerCategory = () => {
  const dispatch = useAppDispatch();
  const {
    form,
    setForm,
    error,
    setError,
    isLoading,
    setIsLoading,
    isDisabled,
    previousAction,
  } = useFormCustomerCategory();

  const handleClick = async () => {
    const response = await postCustomerCategory_adapter(
      form,
      dispatch,
      setIsLoading
    );
    if (response.isSuccess) {
      previousAction();
    }
  };

  const buttonProps = {
    textBttn: "Guardar",
    handleClick: handleClick,
    isDisabled: isLoading || isDisabled,
    isLoading: isLoading,
    isPrimary: true,
  };

  const props = {
    form,
    setForm,
    error,
    setError,
  };

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title={"Nueva categoría de cliente"}
        previousAction={previousAction}
        buttonProps={buttonProps}
      />
      <div className={style.container_customer_category}>
        <CustomerCategoryName {...props} />
        <div>
          <label
            className={`${style.label} ${style.title}`}
            htmlFor="discounts"
          >
            Descuentos
          </label>
          <p className={style.discounts}>
            Ingresa al menos un descuento que se aplicará a esta categoría.
            <span className={style.inputRequired}>*</span>
          </p>
          <div className={style.container_discounts}>
            <ServicesDiscount {...props} />
            <ProductsDiscount {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
