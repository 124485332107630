import { updateNotification } from "@ReduxService/states/notificationSlice";
import { postCustomerCategory_service } from "@Services/customerCategory/postCustomerCategory.service";

export const postCustomerCategory_adapter = async (
  data: any,
  dispatch,
  setIsLoading
) => {
  setIsLoading(true);
  const adaptData = {
    categoryName: String(data.customerCategoryName),
    serviceDiscountPercentage: Number(data.serviceDiscount),
    productDiscountPercentage: Number(data.productsDiscount),
  };
  const response = await postCustomerCategory_service(adaptData);
  setIsLoading(false);
  dispatch(
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    })
  );
  return response;
};
