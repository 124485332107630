import { IForm } from "@Models/interfaces/formPromotions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ItemProduct {
  idProduct: string;
  quantity: string;
  idItem: string;
  productName: string;
  price: number;
}
export interface ItemService {
  idService: string;
  quantity: string;
  idItem: string;
  serviceName: string;
  price: number;
}
export interface PromotionForm {
  form: IForm;
  savedServices: ItemService[] | [];
  savedProducts: ItemProduct[] | [];
  selectedServices: ItemService[] | [];
  selectedProducts: ItemProduct[] | [];
}
const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1); // Restar un día a la fecha actual

const todayString = today.toISOString().split("T")[0];

const initialState: PromotionForm = {
  form: {
    typePromotion: "Paquete",
    promotionName: "",
    startDate: todayString,
    endDate: "",
    completedAvailability: false,
    daysAndHoursOfAvailability: [
      {
        itemId: "",
        codeDay: "",
        startHour: "",
        endHour: "",
      },
    ],
    codePromotion: "",
    stock: "",
    withoutLimit: false,
    paymentMethods: [],
    discount: "",
    typeDiscount: "",
    priceServiceId: [],
    localProductId: [],
    packageCost: "",
    conditions: "",
    localId: [],
  },
  savedServices: [],
  selectedServices: [],
  savedProducts: [],
  selectedProducts: [],
};

export const promotionFormSlice = createSlice({
  name: "promotionForm",
  initialState: initialState,
  reducers: {
    updateTypePromotion(
      state,
      action: PayloadAction<"Descuento" | "Cupón de descuento" | "Paquete" | "">
    ) {
      state.form.typePromotion = action.payload;
    },
    updatePromotionName(state, action: PayloadAction<string>) {
      state.form.promotionName = action.payload;
    },
    updateStartDate(state, action: PayloadAction<string>) {
      state.form.startDate = action.payload;
    },
    updateEndDate(state, action: PayloadAction<string>) {
      state.form.endDate = action.payload;
    },
    updateCompletedAvailability(state, action: PayloadAction<boolean>) {
      state.form.completedAvailability = action.payload;
    },
    updatedaysAndHoursOfAvailability(state, action: PayloadAction<any>) {
      state.form.daysAndHoursOfAvailability = action.payload;
    },
    updateCodePromotion(state, action: PayloadAction<string>) {
      state.form.codePromotion = action.payload;
    },
    updateStock(state, action: PayloadAction<string>) {
      state.form.stock = action.payload;
    },
    updatePaymentMethods(state, action: PayloadAction<any>) {
      state.form.paymentMethods = action.payload;
    },
    updateDiscount(state, action: PayloadAction<string>) {
      state.form.discount = action.payload;
    },
    updateTypeDiscount(state, action: PayloadAction<string>) {
      state.form.typeDiscount = action.payload;
    },
    updatePriceServiceId(state, action: PayloadAction<any>) {
      state.form.priceServiceId = action.payload;
    },
    updateLocalProductId(state, action: PayloadAction<any>) {
      state.form.localProductId = action.payload;
    },
    updatePackageCost(state, action: PayloadAction<string>) {
      state.form.packageCost = action.payload;
    },
    updateWithoutLimit(state, action: PayloadAction<boolean>) {
      state.form.withoutLimit = action.payload;
    },
    updateConditions(state, action: PayloadAction<string>) {
      state.form.conditions = action.payload;
    },
    updateLocalId(state, action: PayloadAction<any>) {
      state.form.localId = action.payload;
    },
    // updateCareChannels(state, action: PayloadAction<string>) {
    //   state.form.careChannels = action.payload;
    // },
    updateSelectedServices(state, action: PayloadAction<any>) {
      state.selectedServices = action.payload;
    },
    updateSavedServices(state, action: PayloadAction<any>) {
      state.savedServices = action.payload;
    },
    updateSelectedProducts(state, action: PayloadAction<any>) {
      state.selectedProducts = action.payload;
    },
    updateSavedProducts(state, action: PayloadAction<any>) {
      state.savedProducts = action.payload;
    },
    setPromotionForm(state, action: PayloadAction<PromotionForm>) {
      state.form = action.payload.form;
      state.savedServices = action.payload.savedServices;
      state.savedProducts = action.payload.savedProducts;
      state.selectedServices = action.payload.selectedServices;
      state.selectedProducts = action.payload.selectedProducts;
    },
    clearForm(state) {
      state.form = {
        typePromotion: "Paquete",
        promotionName: "",
        startDate: todayString,
        endDate: "",
        completedAvailability: false,
        daysAndHoursOfAvailability: [
          {
            itemId: "",
            codeDay: "",
            startHour: "",
            endHour: "",
          },
        ],
        codePromotion: "",
        stock: "",
        withoutLimit: false,
        paymentMethods: [],
        discount: "",
        typeDiscount: "",
        priceServiceId: [],
        localProductId: [],
        packageCost: "",
        conditions: "",
        localId: [],
        // careChannels: "",
      };
      state.savedServices = [];
      state.savedProducts = [];
      state.selectedServices = [];
      state.selectedProducts = [];
    },
  },
});

export default promotionFormSlice.reducer;

export const {
  updateTypePromotion,
  updatePromotionName,
  updateStartDate,
  updateEndDate,
  updateCompletedAvailability,
  updatedaysAndHoursOfAvailability,
  updateCodePromotion,
  updateStock,
  updateWithoutLimit,
  updatePaymentMethods,
  updateDiscount,
  updateTypeDiscount,
  updatePriceServiceId,
  updatePackageCost,
  updateLocalProductId,
  updateConditions,
  updateLocalId,
  // updateCareChannels,
  updateSelectedServices,
  updateSavedServices,
  updateSelectedProducts,
  updateSavedProducts,
  setPromotionForm,
  clearForm,
} = promotionFormSlice.actions;
