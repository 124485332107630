export const stock = "stock";
export const name = "name";
export const startDate = "startDate";
export const endDate = "endDate";
export const active = "Creado";
export const inactive = "Inactiva";
export const creationOrder = "creationOrder";

export const promotionName = "promotionName";
export const codePromotion = "codePromotion";
export const discount = "discount";
export const conditions = "conditions";
export const paymentMethods = "paymentMethods";
