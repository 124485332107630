import {
  updateStock,
  updateWithoutLimit,
} from "@ReduxService/states/promotionsFormSlice";
import style from "./PromotionStock.module.css";
// @ts-ignore
import { TextInput } from "@viuti/recursos";
import { usePromotionContext } from "@Context/promotionContext";
import { useState, useEffect } from "react";

export const PromotionStock = () => {
  const { form, dispatch } = usePromotionContext();
  const [error, setError] = useState("");

  const handleNoRestrictionsChange = (event) => {
    const isChecked = event.target.checked;
    dispatch(updateWithoutLimit(isChecked));
    if (isChecked) {
      dispatch(updateStock("")); // Establece el stock en vacío si está marcado
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (isNaN(value) || value.includes(".")) {
      setError("El stock debe ser un número entero.");
    } else {
      setError("");
    }
    dispatch(updateStock(value));
  };

  return (
    <div>
      <TextInput
        label="Stock"
        placeholder="Ingresar número de stock"
        type="number"
        name="stock"
        value={form.stock}
        handleChange={handleInputChange}
        className={style.input}
        touched={error}
        error={error}
        disabled={form.withoutLimit}
        required
      />
      <div className={style.select_all_container}>
        <input
          type="checkbox"
          id="without-limit"
          name="without-limit"
          value="without-limit"
          onChange={handleNoRestrictionsChange}
          className={style.select_all}
          checked={form.withoutLimit}
        />
        <label
          htmlFor="without-limit"
          className={`${style.labelCheckbox} ${style.select_all}`}
        >
          Sin límite de stock
        </label>
      </div>
    </div>
  );
};
