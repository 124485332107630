// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E4vmD4gSTAueqZqPcKvg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.E4vmD4gSTAueqZqPcKvg figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Iv3TurNOLwojdbUx99jw {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #45348e;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.233px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/DeletePromotion/DeletePromotion.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".form_container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.form_container figure {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.warning_message {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  color: #45348e;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.233px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_container": `E4vmD4gSTAueqZqPcKvg`,
	"warning_message": `Iv3TurNOLwojdbUx99jw`
};
export default ___CSS_LOADER_EXPORT___;
