// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xEL7r9zcha9qo4kBFXAq {
  display: flex;
  gap: 10px;
}

.a0lA7Bpqk0pHjrahaMSp,
.PQM494F_saWwh3M3Jfjx {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 150ms ease-in-out;
}

.a0lA7Bpqk0pHjrahaMSp {
  background-color: #dad1f4;

  &:hover {
    background-color: #e0daf4;
  }
}

.PQM494F_saWwh3M3Jfjx {
  background-color: rgba(244, 124, 124, 0.19);

  &:hover {
    background: rgba(244, 124, 124, 0.5);
  }

  &:active {
    background: rgba(184, 93, 93, 0.5);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PromotionsTable/RowActionsPromotions/RowActionsPromotions.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;;EAEzB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,2CAA2C;;EAE3C;IACE,oCAAoC;EACtC;;EAEA;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".row_actions {\n  display: flex;\n  gap: 10px;\n}\n\n.icon__edit,\n.icon__delete {\n  width: 35px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  transition: all 150ms ease-in-out;\n}\n\n.icon__edit {\n  background-color: #dad1f4;\n\n  &:hover {\n    background-color: #e0daf4;\n  }\n}\n\n.icon__delete {\n  background-color: rgba(244, 124, 124, 0.19);\n\n  &:hover {\n    background: rgba(244, 124, 124, 0.5);\n  }\n\n  &:active {\n    background: rgba(184, 93, 93, 0.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row_actions": `xEL7r9zcha9qo4kBFXAq`,
	"icon__edit": `a0lA7Bpqk0pHjrahaMSp`,
	"icon__delete": `PQM494F_saWwh3M3Jfjx`
};
export default ___CSS_LOADER_EXPORT___;
