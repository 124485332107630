import { useEffect, useState } from "react";
import style from "./DayAvailability.module.css";
// @ts-ignore
import { SelectInput, Icon } from "@viuti/recursos";
import { TrashCanIcon } from "@Models/icons";
import { convertToMinutes, generateTimeSlots } from "@Utilities/formatHour";

const days = [
  { value: 1, label: "L" },
  { value: 2, label: "M" },
  { value: 3, label: "X" },
  { value: 4, label: "J" },
  { value: 5, label: "V" },
  { value: 6, label: "S" },
  { value: 7, label: "D" },
];

export const DayAvailability = ({
  day,
  index,
  handleDayChange,
  removeDayAvailability,
  form,
  isSingle,
}) => {
  const [startHourError, setStartHourError] = useState("");
  const [endHourError, setEndHourError] = useState("");
  const [isAllDay, setIsAllDay] = useState(false);

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value.trim()) {
      if (name === "startHour") {
        setStartHourError("Campo requerido");
      } else {
        setEndHourError("Campo requerido");
      }
    } else {
      validateHours(day.startHour, day.endHour);
    }
  };

  const handleChange = (e, field) => {
    handleDayChange(index, { [field]: e.target.value });
    if (field === "startHour") {
      setStartHourError("");
    } else {
      setEndHourError("");
    }
    validateHours(day.startHour, day.endHour);
  };

  const validateHours = (startHour, endHour) => {
    if (startHour && endHour) {
      const start = convertToMinutes(startHour);
      const end = convertToMinutes(endHour);
      const message = start >= end ? "Hora inválida" : "";

      setStartHourError(message);
      setEndHourError(message);
    }
  };

  const handleNoRestrictionsChange = (e) => {
    setIsAllDay(e.target.checked);
    if (e.target.checked) {
      setStartHourError("");
      setEndHourError("");
    }
  };

  useEffect(() => {
    if (isAllDay) {
      handleDayChange(index, { startHour: "0:00", endHour: "23:45" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllDay]);

  useEffect(() => {
    validateHours(day.startHour, day.endHour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.daysAndHoursOfAvailability]);

  return (
    <div className={style.dayAvailability}>
      <div className={style.daysContainer}>
        <p className={style.label}>
          Por dia
          <span className={style.inputRequired}>*</span>
        </p>
        <div className={style.daysButtons}>
          {days.map(({ value, label }) => (
            <button
              key={value}
              className={`${style.dayButton} ${
                day.codeDay === value ? style.selected : ""
              }`}
              onClick={() => handleDayChange(index, { codeDay: value })} // Cambiado para pasar un objeto
            >
              {label}
            </button>
          ))}
        </div>
      </div>
      <div className={style.hoursContainer}>
        <div className={style.hoursInputs}>
          <SelectInput
            label="Desde"
            containerStyle={{ maxWidth: 150 }}
            value={isAllDay ? "0:00" : day.startHour}
            placeholder={isAllDay ? "0:00" : "Hora de inicio"}
            items={generateTimeSlots().map((time) => ({
              name: time,
              value: time,
            }))}
            handleChange={(e) => handleChange(e, "startHour")}
            handleBlur={handleBlur}
            name="startHour"
            touched={!!startHourError}
            error={startHourError}
            disabled={isAllDay}
            required
          />
          <SelectInput
            label="Hasta"
            containerStyle={{ maxWidth: 150 }}
            value={isAllDay ? "23:45" : day.endHour}
            placeholder={isAllDay ? "23:45" : "Hora de fin"}
            items={generateTimeSlots().map((time) => ({
              name: time,
              value: time,
            }))}
            handleChange={(e) => handleChange(e, "endHour")}
            handleBlur={handleBlur}
            name="endHour"
            touched={!!endHourError}
            error={endHourError}
            disabled={isAllDay}
            required
          />
          <div className={style.select_all_container}>
            <input
              type="checkbox"
              id={`${index}-all-day`}
              name={`${index}-all-day`}
              value={`${index}-all-day`}
              checked={isAllDay}
              onChange={handleNoRestrictionsChange}
              className={style.select_all}
            />
            <label
              htmlFor={`${index}-all-day`}
              className={`${style.labelCheckbox} ${style.select_all}`}
            >
              Todo el día
            </label>
          </div>
        </div>
      </div>
      <button onClick={() => !isSingle && removeDayAvailability(index)}>
        <Icon
          path={TrashCanIcon}
          color={!isSingle ? "#C36363" : "#9f9f9f"}
          size={16}
        />
      </button>
    </div>
  );
};
