import { useState } from "react";
// @ts-ignore
import { TextInput } from "@viuti/recursos";
import style from "./CustomerCategoryName.module.css";

export const CustomerCategoryName = ({ form, setForm, error, setError }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    setForm({ ...form, customerCategoryName: value });
  };

  const handleBlur = () => {
    if (!form.customerCategoryName) {
      setError((prev) => ({
        ...prev,
        customerCategoryNameError:
          "El nombre de la categoría no puede estar vacío.",
      }));
    } else {
      setError((prev) => ({ ...prev, customerCategoryNameError: "" }));
    }
  };

  return (
    <div>
      <TextInput
        label="Nombre de la categoría"
        type="text"
        name="customerCategoryName"
        placeholder="Ingresar el nombre de la categoría de cliente"
        value={form.customerCategoryName}
        handleChange={handleInputChange}
        handleBlur={handleBlur}
        className={style.input}
        required
        error={error.customerCategoryNameError}
        touched={error.customerCategoryNameError}
      />
    </div>
  );
};
