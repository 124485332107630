import { useState } from "react";
// @ts-ignore
import { TextInput } from "@viuti/recursos";
import { Waypoint } from "react-waypoint";
import style from "./PromotionName.module.css";
import { updatePromotionName } from "@ReduxService/states/promotionsFormSlice";
import { usePromotionContext } from "@Context/promotionContext";

export const PromotionName = ({ setCurrentView }) => {
  const { form, dispatch } = usePromotionContext();
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { value } = e.target;
    dispatch(updatePromotionName(value));
  };

  const handleBlur = () => {
    // Example validation: Check if promotionName is empty
    if (!form.promotionName.trim()) {
      setError("El nombre de la promoción no puede estar vacío.");
    } else {
      setError("");
    }
  };

  const label =
    form.typePromotion === "Paquete"
      ? "Nombre del paquete"
      : "Nombre de la promoción";
  const placeholder =
    form.typePromotion === "Paquete"
      ? "Ingresar el nombre del paquete"
      : "Ingresar el nombre de la promoción";

  return (
    <div>
      <TextInput
        label={label}
        type="text"
        name="promotionName"
        placeholder={placeholder}
        value={form.promotionName}
        handleChange={handleInputChange}
        handleBlur={handleBlur}
        className={style.input}
        id="datos-basicos"
        required
        error={error}
        touched={error}
      />
      <Waypoint
        onEnter={() => {
          setCurrentView(0);
        }}
        onLeave={() => {
          setCurrentView(1);
        }}
      />
    </div>
  );
};
