import { Promotion } from "@Models/interfaces/promotions";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface PromotionsConfig {
  promotions: Promotion[];
  currentPage: number;
  totalPages: number;
}

const initialStatePromotions: PromotionsConfig = {
  promotions: [],
  currentPage: 1,
  totalPages: 1,
};

const promotionsSlice = createSlice({
  name: "promotions",
  initialState: initialStatePromotions,
  reducers: {
    updatePromotions(state, action: PayloadAction<Promotion[]>) {
      state.promotions = action.payload;
    },
    updateCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    updateTotalPages(state, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
  },
});

export default promotionsSlice.reducer;
export const { updatePromotions, updateCurrentPage, updateTotalPages } =
  promotionsSlice.actions;
