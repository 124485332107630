// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vvma2OHiLKyqqevFKfwi {
  padding: 24px;
  border: 1px solid #dadada;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Promotions/PromotionForm/components/TotalPackageValue/TotalPackageValue.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".totalPackagePrice {\n  padding: 24px;\n  border: 1px solid #dadada;\n  border-radius: 8px;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalPackagePrice": `Vvma2OHiLKyqqevFKfwi`
};
export default ___CSS_LOADER_EXPORT___;
