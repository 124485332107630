import { updateNotification } from "@ReduxService/states/notificationSlice";
import { clearForm } from "@ReduxService/states/promotionsFormSlice";
import { postPromotion_service } from "@Services/promotions/postPromotion.service";

export const postPromotion_adapter = async (
  data: any,
  dispatch,
  navigation,
  setIsLoadingCreatePromotion
) => {
  setIsLoadingCreatePromotion(true);
  const adaptRequest = {
    promotionName: String(data.promotionName),
    promotionType: {
      idPromotionType: 3,
      price: data.packageCost,
    },
    startDate: String(data.startDate),
    endDate: String(data.endDate),
    dayToCreatePromotions: data.completedAvailability
      ? []
      : data.daysAndHoursOfAvailability?.map((day: any) => ({
          codeDay: String(day?.codeDay),
          startHour: String(day?.startHour),
          endHour: String(day?.endHour),
        })),
    stock: Number(data?.stock) || null,
    // codePromotion: String(data.codePromotion),
    paymentMethods: data.paymentMethods.map((method: any) =>
      Number(method.idPaymentMethod)
    ),
    // discount: Number(data.discount),
    // typeDiscount: Number(data.typeDiscount),
    itemsPromotionService: data.priceServiceId.map((service: any) => ({
      priceServiceId: service.idService,
      quantity: service.quantity || 1,
    })),
    itemsPromotionProduct: data.localProductId.map((product: any) => ({
      idProduct: product.idProduct,
      quantity: product.quantity || 1,
    })),
    conditions: String(data.conditions),
    localId: data.localId.map((id: any) => Number(id)),
    isCumulative: true,
    // careChannels: Number(data.careChannels),
  };

  const response = await postPromotion_service(adaptRequest);
  dispatch(
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    })
  );
  if (response.isSuccess) {
    dispatch(clearForm());
    navigation("/");
  }
  setIsLoadingCreatePromotion(false);
  return response;

  // setTimeout(() => {
  //   dispatch(
  //     updateNotification({
  //       message: "Promoción creada correctamente",
  //       status: "success",
  //       toastTimeDuration: 4000,
  //     })
  //   );
  // }, 2000);
  // setTimeout(() => {
  //   navigation("/");
  // }, 3000);
};

// {
//   "promotionName": "string",
//   "promotionType": {
//     "idPromotionType": 0,
//     "price": 0
//   },
//   "startDate": "string",
//   "endDate": "string",
//   "dayToCreatePromotions": [
//     {
//       "codeDay": 0,
//       "startHour": "string",
//       "endHour": "string"
//     }
//   ],
//   "stock": 0,
//   "paymentMethods": [
//     0
//   ],
//   "serviceId": [
//     0
//   ],
//   "productId": [
//     0
//   ],
//   "conditions": "string",
//   "localId": [
//     0
//   ],
//   "isCumulative": true
// }
