import React, { useState, useEffect } from "react";
import style from "./CreatePackage.module.css";
import { usePromotionContext } from "@Context/promotionContext";
import { updatePackageCost } from "@ReduxService/states/promotionsFormSlice";
// @ts-ignore
import { TextInput } from "@viuti/recursos";
import { formatToSoles } from "@Utilities/FormatsHandlers";

export const PackageCost = ({ total }: { total: number }) => {
  const { form, dispatch } = usePromotionContext();
  const [errors, setErrors] = useState({
    packageCost: "",
  });

  useEffect(() => {
    if (form.packageCost !== "") {
      const cost = parseFloat(form.packageCost);
      if (cost > total) {
        setErrors({
          packageCost:
            "El precio del paquete no puede ser mayor al valor estimado",
        });
      } else {
        if (
          errors.packageCost ===
          "El precio del paquete no puede ser mayor al valor estimado"
        ) {
          setErrors({ packageCost: "" });
        }
      }
    }
  }, [form.packageCost, total]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    dispatch(updatePackageCost(value));
  };

  const handleBlur = () => {
    if (form.packageCost === "") {
      setErrors({ packageCost: "El precio del paquete es requerido" });
    } else if (isNaN(form.packageCost)) {
      setErrors({
        packageCost: "El precio del paquete debe ser un número válido",
      });
    } else if (parseFloat(form.packageCost) > total) {
      setErrors({
        packageCost:
          "El precio del paquete no puede ser mayor al valor estimado",
      });
    } else {
      setErrors({ packageCost: "" });
      dispatch(updatePackageCost(formatToSoles(form.packageCost, false)));
    }
  };

  return (
    <div>
      <label className={`${style.label} ${style.title}`} htmlFor="packageCost">
        Precio
      </label>
      <TextInput
        label="Precio del paquete"
        type="number"
        placeholder="0.00"
        name="packageCost"
        value={form.packageCost}
        handleChange={handleInputChange}
        handleBlur={handleBlur}
        error={errors.packageCost}
        touched={errors.packageCost}
        required
        prefix="S/."
      />
    </div>
  );
};
