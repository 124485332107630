import { CustomerCategory } from "@ReduxService/states/customerCategorySlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getCustomerCategory_service } from "@Services/customerCategory/getCustomerCategory.service";

export const getCustomerCategoryDetail_adapter = async (dispatch, id) => {
  const response = await getCustomerCategory_service();
  const formatId = Number(id);
  if (!response.isSuccess) {
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
    return {
      ...response,
      data: {
        customerCategoryDetail: {
          id: 0,
          name: "",
          discountService: 0,
          discountProduct: 0,
        },
      },
    };
  }
  const adaptCustomerCategory = response.data.map((customerCategory) => {
    return {
      id: customerCategory.categoryClientId,
      name: customerCategory.categoryName,
      discountService: customerCategory.serviceDiscountPercentage,
      discountProduct: customerCategory.productDiscountPercentage,
    };
  });

  const category: CustomerCategory = adaptCustomerCategory.find(
    (customerCategory) => customerCategory.id === formatId
  );

  return {
    ...response,
    data: {
      customerCategoryDetail: category,
    },
  };
};
